.menu-region {

    & > div {
        position:fixed;
        top:0;
        left:0;
        height:100%;
        width:100%;
        z-index:15;
        overflow:hidden;
    }

    .icon {
        fill:$black;
    }

    .mask {
        height:100%;
        width:100%;
    }

    .scale-lower {
        height:100%;
        width:100%;
    }

    .button-menu {
        position:absolute;
        bottom:30px;
        left:30px;
        font:500 14px/40px $font-text;
        letter-spacing:0.2em;
        color:$black;
        border:1px solid $black;
        padding:0 10px;
        cursor:pointer;
        z-index:4;

        .icon {
            margin-right:10px;
            position:relative;
        }
    }

    .title {
        font:400 26px/26px $font-big-title;
        position:absolute;
        bottom:80px;
        left:80px;
        text-align:right;
        z-index:4;
        color:$black;

        span {
            display:block;
            font:400 14px/24px $font-title;
            letter-spacing:0.08em;
        }
    }

    .worldwide-button {
        font:400 14px/20px $font-text;
        color:$black;
        position:absolute;
        bottom:4px;
        right:10px;
        z-index:6;
        padding:20px;
    }

    .background {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;

        div {
            height:100%;
            width:percentage(1/7);
            float:left;
            overflow:hidden;

            div {
                width:102%;
                position:relative;
                left:-1%;
                background:$white;
            }
        }
    }

    .menu-button-container .character-infos .button {
        cursor: pointer;
        width: 130px;
        height: 42px;

        .mask span {
            width: 130px;
        }
    }
}


// -----------------o Main nav

.menu-characters {
    height:100%;
    max-width:1400px;
    padding:0 50px;
    box-sizing:border-box;
    margin:0 auto;
    position:absolute;
    top:0;
    left:0;
    z-index:2;
    width:100%;
    left:50%;
    transform:translateX(-50%);
    z-index:4;
    pointer-events:none;

    li {
        height:100%;
        width:percentage(1/8);
        float:left;
    }

    .mask {
        width:200%;
        left:0%;
        position:relative;
    }

    img {
        display:block;
        position:absolute;
        bottom:30%;
        width:100%;
    }

    li:nth-child(1) .mask           {width:93%;left:3%;}
    li:nth-child(2) .mask           {width:100%;}
    li:nth-child(3) .mask           {width:105%;left:-10%;}
    li:nth-child(4) .mask           {width:118%;left:-15%;}
    li:nth-child(5) .mask           {width:130%;left:-13%;}
    li:nth-child(6) .mask           {width:90%;left:12%;}
    li:nth-child(7) .mask           {width:70%;left:10%;transform:rotate(10deg);}
    li:nth-child(8) .mask           {width:130%;left:-15%;}
}


// -----------------o Main nav
.main-nav {
    height:100%;
    max-width:1400px;
    padding:0 50px;
    box-sizing:border-box;
    margin:0 auto;
    position:relative;
    z-index:3;

    a, .disabled {
        display:block;
        height:100%;
        width:100%;
        outline: none;
    }

    li {
        height:100%;
        width:percentage(1/8);
        float:left;
        position:relative;
        cursor:pointer;
    }

    img {
        display:block;
        max-width:100%;
    }

    .front {
        position:absolute;
        bottom:30%;
        margin-bottom:-80px;
        left:0;
        width:100%;
        height:auto;
        text-align:center;
        overflow:visible;

        .safari-show {
            display: none;
        }
    }

    strong {
        color:$gold;
        font:500 16px/20px $font-text;
        letter-spacing:0.2em;
        display:block;
        height:40px;
        margin-top:20px;
    }

    span {
        position:absolute;
        bottom:-55px;
        left:0;
        width:100%;
        color:$white;
        font:400 20px/20px $font-title;
        letter-spacing:0.25em;
        color:$black;
        opacity:0;
    }

    .band {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        background:$gold;
    }

    .disabled {
        .band {
            background:$grey;
        }
        strong {
            color:$grey;
        }
    }

    // -----------------------------------o

    .band {
        transform:translate3d(0, 100%, 0);
        transition:transform 1000ms $easeOutExpo 0ms;
    }

    strong {
        transition:color 1000ms $easeOutExpo 0ms;
    }

    span {
        transition:opacity 1000ms $easeOutExpo 100ms;
    }

    li:hover {
        .band {
            transform:translate3d(0, 0, 0);
        }

        strong {
            color:$black;
        }

        span {
            opacity:1;
        }
    }

}

.safari {
    .main-nav .front {
        .safari-show {
            display: block;

            p {
                display: inline-block;

                &:first-child {
                    left: 6px;
                    position: relative;
                    width: 16px;
                }
            }
        }

        .safari-hide {
            display: none;
        }
    }
}







// ----------------------------------------o Animations

.menu-region {
    $closeDuration: 1000ms;

    & > div {
        transition-delay:$closeDuration;
        transform:translate3d(-100%, 0, 0);
    }

    // ---o Masks
    .mask {
        overflow:hidden;
        transition:transform $closeDuration $easeOutExpo 0ms;
    }

    // ---o Background

    .background {
        div > div {
            //transition-delay:$closeDuration;
            transform:translate3d(-101%, 0, 0);
        }
    }

    // ---o Social & Title

    .social {
        li {
            //transition-delay:$closeDuration;
            transform:translate3d(50px, 0, 0);
            opacity:0;
        }
    }

    .title {
        //transition-delay:$closeDuration;
        transform:translate3d(-50px, 0, 0);
        opacity:0;
    }

    // ---o Nav

    .main-nav {

        a, .disabled {
            overflow:hidden;
            position:relative;
        }

        img {
            transform:translate3d(100%, 0, 0);
            transition:transform $closeDuration $easeOutExpo 0ms;
        }

        .mask {
            transform:translate3d(100%, 0, 0);
            transition:transform 0ms $easeOutExpo 0ms;
        }

    }

    &.displayed {
        & > div {
            transform:translate3d(0, 0, 0);
            transition-delay:0ms;
        }

         // ---o Masks

        .mask, img {
            transform:translate3d(0, 0, 0);
            transition:transform $closeDuration $easeOutExpo 0ms;
        }

        .title {
            opacity:1;
        }

        .main-nav,
        .menu-characters {
            $delay: 300ms;
            li:nth-child(1) {
                .mask, img           {transition-delay:$delay + 0 * 100ms;}
            }
            li:nth-child(2) {
                .mask, img           {transition-delay:$delay + 1 * 100ms;}
            }
            li:nth-child(3) {
                .mask, img           {transition-delay:$delay + 2 * 100ms;}
            }
            li:nth-child(4) {
                .mask, img           {transition-delay:$delay + 3 * 100ms;}
            }
            li:nth-child(5) {
                .mask, img           {transition-delay:$delay + 4 * 100ms;}
            }
            li:nth-child(6) {
                .mask, img           {transition-delay:$delay + 5 * 100ms;}
            }
            li:nth-child(7) {
                .mask, img           {transition-delay:$delay + 6 * 100ms;}
            }
            li:nth-child(8) {
                .mask, img           {transition-delay:$delay + 7 * 100ms;}
            }
        }



        // ---o Background
        .background {

            div {

                & > div {
                    transform:translate3d(0, 0, 0);
                    transition:transform 1400ms $easeOutExpo 0ms;
                }

                &:nth-child(1) div      {transition-delay:0ms;}
                &:nth-child(2) div      {transition-delay:50ms;}
                &:nth-child(3) div      {transition-delay:100ms;}
                &:nth-child(4) div      {transition-delay:150ms;}
                &:nth-child(5) div      {transition-delay:200ms;}
                &:nth-child(6) div      {transition-delay:250ms;}
                &:nth-child(7) div      {transition-delay:300ms;}
            }
        }

    }

    &.pre-displayed {
        .mask, img {
            transition-duration:0;
            transform:translate3d(100%, 0, 0);
        }
    }

    &.hidden {

        // ---o Masks

        .mask-container {
            transform:translate3d(100%, 0, 0);
        }

        .mask-center {
            transform:translate3d(-90%, 0, 0);
        }
    }
}



// ----------------------------------------o Safari
$bgWidth: 14.2%;

.safari .menu-region {
    position: relative;

    .background {
        div {
            position: absolute;
        }

        div:nth-child(1) {
            left: 0;
        }

        div:nth-child(2) {

            left: $bgWidth * 1;
        }

        div:nth-child(3) {
            left: $bgWidth * 2;
        }

        div:nth-child(4) {
            left: $bgWidth * 3;
        }

        div:nth-child(5) {
            left: $bgWidth * 4;

        }

        div:nth-child(6) {
            left: $bgWidth * 5;

        }

        div:nth-child(7) {
            left: $bgWidth * 6;
            width: 15%;
        }
    }
}
