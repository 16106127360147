// put all SCSS specific to the home page in this section to avoid clashes with other sections
.home-region,
.menu-button-container {
    //overflow:hidden;
    position:relative;
    width:100%;
    //overflow:hidden;


    .date-message--small {
        font-size: 25px;
        line-height: 30px;
    }


    .intro {
        position:absolute;
        top:0;
        left:0;
        overflow:hidden;
        z-index:3;

        .intro-video {
            position:absolute;
            overflow:hidden;
        }

        &.hidden {
            opacity:0;
            transition:opacity 1000ms $easeOutExpo 0ms;
        }
    }

    .home-top {
        display:none;
        position:relative;
        height:100%;
        width:100%;
        overflow:hidden;
        background:$black;

        .background {
            position:absolute;
            background:$black;

            img {
                display:block;
                height:100%;
                width:100%;
            }
        }

        .bottom-links {
            position:absolute;
            font:400 15px/15px $font-title;
            bottom:36px;
            right:40px;
            font:500 14px/30px $font-text;
            letter-spacing:0.1em;

            a {
                margin-left:30px;
                color:$gold;
                display:block;
                position:absolute;
                bottom:0;
                right:0;
                text-align:right;
                border-top:1px solid $gold;
                border-bottom:1px solid $gold;
                white-space:nowrap;
                padding:0 10px;

                opacity:0;
                transition:opacity 1000ms $easeOutExpo 0ms;

                .icon {
                    fill:$gold;
                    height:20px;
                    width:20px;
                    position:relative;
                    top:4px;
                    margin-right:10px;
                }

                &.active {
                    opacity:1;
                    transition-delay:300ms;
                    z-index: 1;
                }
            }
        }

        .button {
            background:rgba($black, 0.5);
            width:220px;
            padding:0;

            .mask span {
                width:220px;
            }
        }

    }

    .scroll-button {
        position:absolute;
        bottom:0;
        line-height:90px;
        font:400 14px/18px $font-title;
        color:$gold;
        left:50%;
        margin-left:-100px;
        width:200px;
        text-align:center;
        letter-spacing:0.1em;
        cursor:pointer;
        padding:27px 0;

        strong {
            font-size:24px;
            display:block;
        }
    }

    video {
        height:100%;
        width:100%;
        display:block;
    }

    .center {
        position:absolute;
        top:50%;
        left:0;
        z-index:2;
        color:$gold;
        text-align:center;
        width:100%;
        margin-top:-155px;
    }

    h1 {
        font:400 140px/122px $font-big-title;
    }

    h2 {
        font:400 32px/40px $font-big-title;
        letter-spacing:0.08em;
    }

    h3 {
        font:400 45px/50px $font-title;
        margin-top:10px;
        letter-spacing:0.08em;
        margin-bottom:60px;
    }

    .button {
        margin:0 10px;
        position:relative;
        display:inline-block;
        padding:0 36px;
        height:56px;
        line-height:54px;
        border:1px solid rgba($white, 0.5);

        p {
            position:relative;
            z-index:2;
            display:block;
        }

        .bands {
            position:absolute;
            top:0;
            left:0;
            height:100%;
            width:100%;

            .mask {
                display:block;
                height:100%;
                width:percentage(1/7) + 0.3;
                float:left;
                position:relative;
                overflow:hidden;
                color:$black;
                position:absolute;
                top:0;

                div {
                    position:relative;
                    width:100%;
                    height:100%;
                    overflow:hidden;
                }

                .out {
                    width:100%;
                }

                &:nth-child(1) {
                    left:percentage(1/7) * 0;
                    span {
                        margin-left:100% * -0;
                    }
                }
                &:nth-child(2) {
                    left:percentage(1/7) * 1;
                    span {
                        margin-left:100% * -1;
                    }
                }
                &:nth-child(3) {
                    left:percentage(1/7) * 2;
                    span {
                        margin-left:100% * -2;
                    }
                }
                &:nth-child(4) {
                    left:percentage(1/7) * 3;
                    span {
                        margin-left:100% * -3;
                    }
                }
                &:nth-child(5) {
                    left:percentage(1/7) * 4;
                    span {
                        margin-left:100% * -4;
                    }
                }
                &:nth-child(6) {
                    left:percentage(1/7) * 5;
                    span {
                        margin-left:100% * -5;
                    }
                }
                &:nth-child(7) {
                    left:percentage(1/7) * 6;
                    span {
                        margin-left:100% * -6;
                    }
                }
            }

            span {
                display:block;
                height:100%;
                white-space:nowrap;
                background:$white;
            }
        }

        // ---------o Animations

        & {
            transition:border-color 2000ms $easeOutExpo 0ms, background 2000ms $easeOutExpo 0ms;
        }

        .bands {
            .mask {
                div {
                    transition:transform 700ms $easeOutExpo 0ms;
                }
                .in {
                    transform:translate3d(-105%, 0, 0);
                }
            }
        }

        &.displayed {
            border-color:rgba($white, 0);
            background:rgba($black, 0);

            .mask {
                .in {
                    transform:translate3d(0, 0, 0);
                }

                &:nth-child(1) .in          {transition-delay:50ms * 0;}
                &:nth-child(2) .in          {transition-delay:50ms * 1;}
                &:nth-child(3) .in          {transition-delay:50ms * 2;}
                &:nth-child(4) .in          {transition-delay:50ms * 3;}
                &:nth-child(5) .in          {transition-delay:50ms * 4;}
                &:nth-child(6) .in          {transition-delay:50ms * 5;}
                &:nth-child(7) .in          {transition-delay:50ms * 6;}

            }
        }

        &.hidden {

            .mask {
                .out {
                    transform:translate3d(105%, 0, 0);
                }

                .in {
                    transform:translate3d(0, 0, 0);
                }

                &:nth-child(1) .out          {transition-delay:50ms * 0;}
                &:nth-child(2) .out          {transition-delay:50ms * 1;}
                &:nth-child(3) .out          {transition-delay:50ms * 2;}
                &:nth-child(4) .out          {transition-delay:50ms * 3;}
                &:nth-child(5) .out          {transition-delay:50ms * 4;}
                &:nth-child(6) .out          {transition-delay:50ms * 5;}
                &:nth-child(7) .out          {transition-delay:50ms * 6;}

            }
        }
    }



    // --------------------------------------o Display Transition

    h1 {
        transition:opacity 3000ms $easeOutExpo 0ms, transform 3000ms $easeOutExpo 0ms;
    }

    h2 {
        transition:opacity 3000ms $easeOutExpo 100ms, transform 3000ms $easeOutExpo 100ms;
    }

    h3 {
        transition:opacity 3000ms $easeOutExpo 200ms, transform 3000ms $easeOutExpo 200ms;
    }

    a {
        transition:opacity 3000ms $easeOutExpo 300ms, transform 3000ms $easeOutExpo 300ms;
    }

    .home-top .background {
        transition:opacity 3000ms $easeOutExpo 0ms;
    }

    &.pre-displayed {

        h1, h2, h3, a {
            transform:scale(0.9);
            opacity:0;
        }

        .home-top .background {
            opacity:0;
        }

    }


    // ----------------------------------------------------o Characters

    .characters {
        position:relative;
        z-index:3;
    }
    .characters-list {
        overflow:hidden;
        position:absolute;
        top:0;
        left:0;
        z-index:10;
        display:none;
    }

    .characters-nav {
        position:fixed;
        top:60%;
        left:10%;
        z-index:10;
        backface-visibility:hidden;

        .nav-progress {
            position:absolute;
            top:80px;
            left:10px;
            width:120px;
            color:$gold;
            font:400 18px/32px $font-title;
            letter-spacing:0.02em;

            &:before {
                display:block;
                content:'';
                height:1px;
                width:35px;
                background:$gold;
                position:absolute;
                top:-5px;
                left:25px;
            }
        }

        ul {
            font:400 32px/32px $font-title;
            height:32px;
            width:62px;
            overflow:hidden;
            float:left;
            position:relative;
            margin-right:7px;
            top:5px;
        }

        li {
            position:absolute;
            right:0;
            top:0;
            transition:transform 2000ms $easeOutExpo 0ms;
            transform:translate3d(0, -100%, 0);

            &.active {
                transform:translate3d(0, 0, 0);

                & ~ li {
                    transform:translate3d(0, 100%, 0);
                }
            }
        }

        .prev-button, .next-button {
            position:absolute;
            top:0;
            left:-100px;
            transform:rotate(-90deg);
            font:500 13px/30px $font-text;
            text-transform:uppercase;
            letter-spacing:0.2em;
            cursor:pointer;
        }

        .next-button {
            top:100px;
        }
    }

    .character-screen {
        overflow:hidden;
        position:absolute;
        top:0;
        left:0;
        z-index:4;

        .background {
            position:absolute;
            display:none;
        }

        .front {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            max-width:1400px;
            max-height:700px;
            padding:50px;
            box-sizing:border-box;
            top:50%;
            left:50%;
            transform:translate3d(-50%, -50%, 0);
            z-index:2;
        }

    }

    .mask {
        position:relative;
        overflow:hidden;
    }

    .character-name {
        font:400 150px/170px $font-big-title;
        letter-spacing:0.4em;
        color:$gold;
        text-align:center;
        position:absolute;

        .letter {
            display:inline-block;
            text-align:left;

            div {
                width:200%;
                overflow:hidden;
                position:relative;
            }
        }

        span {
            display:block;
            width:100%;
        }
    }

    .character-post-title {
        letter-spacing:0.2em;
        position:absolute;
        overflow:hidden;

        div strong {
            color:$gold;
            font:400 28px/28px $font-big-title;
            margin-right:10px;
        }

        div span {
            color:$gold;
            font:400 30px/30px $font-title;
        }

        p {
            strong {
                display:block;
                font:400 18px/30px $font-text;
            }

            span {
                font:400 30px/30px $font-title;
            }
        }

    }

    .character-infos {
        position:absolute;

        .title {
            display: none;
            color:$gold;
            font:500 13px/30px $font-text;
            margin-bottom:15px;
            overflow:hidden;
        }

        .quote {
            font:400 30px/40px $font-title;
            letter-spacing:0.1em;
            margin-bottom:25px;
            overflow:hidden;
        }

        .button {
            width:225px;
            text-align:center;
            border:1px solid rgba($gold, 0.5);

            .mask span {
                background:$gold;
                color:$white;
                width:225px;
            }
        }

    }

    .button-more {
        position:absolute;
        overflow:hidden;
        width:221px;
        height:302px;

        .button {
            position:absolute;
            top:50%;
            left:50%;
            transform:translate3d(-50%, -50%, 0);
            white-space:nowrap;
            margin:0;
        }

        img {
            display:block;
            height:100%;
            width:100%;
        }
    }

    .button-download {
        position:absolute;
        overflow:hidden;

        p {
            font:500 12px/20px $font-text;
            position:absolute;
            bottom:30px;
            left:0;
            width:100%;
            text-align:center;
            color:$white;
            letter-spacing:0.2em;
            box-sizing:border-box;
            padding:0 40px;
        }
    }

    // -----------------------------------------------------------------------------o Transitions

    .characters-list {

        .characters-nav {
            transition:opacity 2000ms $easeOutExpo 0ms;
            opacity:0;
        }

        &.displayed {

            .characters-nav {
                transition-delay:2000ms;
                opacity:1;
            }

        }

    }

    .transitions-container {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        z-index:2;
        //background:$white;

        &.backgrounded {
            background:$white;
        }

        img {
            backface-visibility:hidden;
        }
    }


    $bandsTiming: 3000ms;
    $bandsNb: 10;
    .bands-container {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        z-index:2;
        perspective:2000px;
        transform-style:preserve-3d;
        .mask {
            position:relative;
            width:100% * $bandsNb;
            height:100%;
            transition:transform ($bandsTiming * 0.8) $easeInOutQuart 0ms;
        }

        .band {
            position:absolute;
            height:100%;
            width:percentage(1/$bandsNb) + 0.1;
            float:left;
            overflow:hidden;
            transition:transform $bandsTiming $easeOutQuint $bandsTiming;
            backface-visibility:hidden;

            $delay: 100ms;
            @for $i from 0 through $bandsNb {
                &:nth-child(#{ $i + 1 }) {
                    left:percentage(1/$bandsNb) * $i;
                    transition-delay:$delay * $i;

                    .mask {
                        left:-100% * $i;
                        transition-delay:$delay * $i;
                    }
                }
            }
        }

        img {
            display:block;
            position:absolute;
        }
    }

    .character-screen {
        display:none;
    }

    .bands-container.leaving,
    .bands-container.pre-displayed {
        & > .background {
            display:none;
        }
    }


    .bands-container.leaving {

        .mask {
            transform:translate3d(20%, 0, 0);
            transition:transform $bandsTiming $easeOutQuint 0ms;
        }

        .band {
            transform:rotateY(180deg);
        }

    }

    .bands-container.pre-displayed {

        .mask {
            transform:translate3d(-20%, 0, 0);
        }

        .band {
            transform:rotateY(-180deg);
        }

    }


    // ---o Screens

    $timing: 2000ms;

    .character-screen {
        .mask {
            transition:transform $timing $easeOutExpo 0ms;
        }

        .letter span {
            transition:transform $timing $easeOutExpo 0ms;
        }
    }

    .character-infos .button {
        transition:opacity $timing $easeOutExpo 0ms;
    }

    .character-screen.leaving {
        .mask {
            transform:translate3d(101%, 0, 0);
        }

        .letter {
            .mask {
                transform:translate3d(0, 0, 0);

                span {
                   transform:translate3d(101%, 0, 0);
                }
            }
        }

        .character-post-title {
            .mask {
                &:nth-child(1){
                    transition-delay:0ms;
                }

                &:nth-child(2){
                    transition-delay:0ms;
                }
            }
        }

        .button-more .mask {
            transition-delay:0ms
        }

        .button-download .mask {
            transition-delay:200ms;
        }

        .character-name .letter {
            $delay:100ms;
            @for $i from 1 through 9 {
                &:nth-child(#{ $i }) span {
                    transition-delay:$delay * $i;
                }
            }
        }

        .quote .mask {
            $delay:100ms;
            @for $i from 1 through 5 {
                &:nth-child(#{ $i }) {
                    transition-delay:$delay * $i;
                }
            }
        }

        .character-infos .button {
            opacity:0;
        }


    }

    .character-screen.pre-displayed {
        .mask {
            transform:translate3d(-101%, 0, 0);
        }

        .letter {
            .mask {
                transform:translate3d(0, 0, 0);

                span {
                   transform:translate3d(-101%, 0, 0);
                }
            }
        }

        .character-infos .button {
            opacity:0;
        }
    }

    .character-screen.displayed {

        .button-more .mask {
            transition-delay:1000ms;
        }

        .button-download .mask {
            transition-delay:1200ms;
        }

        .character-name .letter {
            $delay:50ms;
            @for $i from 1 through 9 {
                &:nth-child(#{ $i }) span {
                    transition-delay:1500ms + $delay * $i;
                }
            }
        }

        .quote .mask {
            $delay:100ms;
            @for $i from 1 through 5 {
                &:nth-child(#{ $i }) {
                    transition-delay:1000ms + $delay * $i;
                }
            }
        }

        .character-infos {
            .title .mask {
                transition-delay:950ms;
            }
            .button {
                transition-delay:$timing;
            }
        }


    }

    .character-screen.pre-displayed,
    .character-screen.displayed {
        .character-post-title {
            .mask {
                transform:translate3d(-100%, 0, 0);
            }
        }
    }

    .character-screen.post-displayed {

        .letter {
            .mask {
                transform:translate3d(0, 0, 0);

                span {
                   transform:translate3d(101%, 0, 0);
                }
            }
        }

        .character-post-title {
            .mask {
                &:nth-child(1){
                    transition-delay:1000ms;
                }

                &:nth-child(2){
                    transition-delay:1100ms;
                }
            }
        }


    }





    // --------------------------------o Specific screen

        // -----------o Mike

    .character-mike {
        .character-name {
            width:630px;
            top:50%;
            left:50%;
            margin:-390px 0 0 -315px;
        }

        .character-post-title {
            top:80px;
            right:50px;
            text-align:right;

            @media screen and (min-width: 1920px) {
                right: -170px;
            }

            @media screen and (min-width: 2200px) {
                right: -270px;
            }
        }

        .character-infos {
            width:250px;
            text-align:right;
            bottom:30px;
            right:30px;

            @media screen and (min-width: 1920px) {
                right: -170px;
            }

            @media screen and (min-width: 2200px) {
                right: -270px;
            }
        }

        .button-download {
            top:50%;
            margin-top:-200px;
            left:70px;
        }
    }


        // -----------o Ken

    .character-ken {
        .character-name {
            width:260px;
            top:50%;
            left:50%;
            margin:-330px 0 0 100px;

            .letter:nth-child(2){
                margin-left:200px;
            }
        }

        .character-post-title {
            left:50%;
            top:50%;
            margin:10% 0 0 -13%;
        }

        .character-infos {
            width:250px;
            top:20%;
            left:70px;
        }

        .button-download {
            left:50%;
            top:50%;
            margin:-20% 0 0 -13%;
        }
    }


        // -----------o Richie

    .character-richie {

        .character-name {
            width:800px;
            top:50%;
            left:50%;
            margin:-330px 0 0 -400px;

            .letter:nth-child(3){
                margin-left:350px;
            }
        }

        .character-post-title {
            top:80px;
            right:50px;
            text-align:right;
        }

        .character-infos {
            left:43%;
            top:50%;
            margin:-20% 0 0 -40%;
            width:300px;
        }

        .button-download {
            bottom:20px;
            left:23%;

            p {
                bottom: 12px;
            }
        }
    }


        // -----------o Tito

    .character-tito {

        .character-name {
            width:800px;
            top:50%;
            left:50%;
            margin:-100px 0 0 -400px;

            .letter:nth-child(3){
                margin-left:250px;
            }
        }

        .character-post-title {
            bottom:80px;
            right:30px;
            text-align:right;
        }

        .character-infos {
            top:55%;
            right:44%;
            width:450px;
            margin:-30% -12% 0 0;
            width:450px;
            text-align:right;
        }

        .button-download {
            right:30px;
            top:80px;
        }
    }


        // -----------o Tarzan

    .character-tarzan {

        .character-name {
            width:800px;
            top:50%;
            left:50%;
            margin:-275px 0 0 -400px;

            .letter:nth-child(3){
                margin-left:250px;
            }
        }

        .character-post-title {
            bottom:320px;
            right:27px;
            text-align:right;
        }

        .character-infos {
            width:450px;
            text-align:right;
            right:30px;
            bottom:30px;
        }

        .button-download {
            left:80px;
            top:50%;
            margin-top:-250px;
        }
    }


        // -----------o Rome

    .character-rome {

        .character-name {
            width:800px;
            top:50%;
            left:50%;
            margin:-250px 0 0 -500px;

            .letter:nth-child(3){
                margin-left:250px;
            }
        }

        .character-post-title {
            top:100px;
            left:30px;
        }

        .character-infos {
            width:450px;
            text-align:right;
            text-align:right;
            right:30px;
            bottom:30px;
        }

        .button-download {
            right:30px;
            top:70px;
        }
    }


        // -----------o Malick

    .character-malick {

        .character-name {
            width:800px;
            top:50%;
            left:50%;
            margin:-290px 0 0 -420px;

            .letter:nth-child(3){
                margin-left:250px;
            }
        }

        .character-post-title {
            right: 40px;
            top: 130px;
            text-align: right;
        }

        .character-infos {
            width:450px;
            text-align:right;
            right:30px;
            top:170px;
        }

        .button-download {
            top:50%;
            margin-top:-200px;
            left:80px;
        }
    }


        // -----------o Andre

    .character-andre {

        .character-name {
            width:800px;
            top:50%;
            left:50%;
            margin:-290px 0 0 -380px;

            .letter:nth-child(3){
                margin-left:250px;
                margin-right:250px;
            }
        }

        .character-post-title {
            bottom:30px;
            right:30px;
            text-align:right;

            @media screen and (min-width: 1920px) {
                right: -170px;
            }
        }

        .character-infos {
            width:450px;
            text-align:right;
            right:30px;
            top:50%;
            margin-top:-20%;

            @media screen and (min-width: 1920px) {
                right: -170px;
            }
        }

        .button-download {
            top:50%;
            margin-top:-200px;
            left:80px;
        }
    }

    .MPAA_requirements {
        @include transition(all 1s ease-in-out);

        position: absolute;
        bottom: 100px;
        background: rgba(0, 0, 0, 0.75);
        width: 180px;
        text-align: center;
        padding: 10px;

        &.hide {
            @include transform(translateX(-180px));
        }

        li {
            font-family: $font-text;
            padding: 8px 0;
            font-size: 14px;

            a {
                color: $gold;
            }
        }
    }

}

.home-region .character-name .safari-show {
    display: none;
}

.safari .home-region .character-name {
    .safari-show {
        display: block;
    }

    .safari-hide {
        display: none;
    }
}

.touch {
    .home-region {
        h1 {
            font-size: 94px;
            line-height: 94px;
            margin-bottom: 30px;
            margin-top: 50px;
        }

        h3 {

        }
    }
}

