.header-region {
    //position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;
    letter-spacing: 0.1em;

    a, span {
        display:block;
        color: $gold;
    }

    span .icon {
        fill:$gold;
        position:relative;
        margin-right:8px;
    }

    .social-buttons {
        position: fixed;
        top: 38px;
        right: 40px;
        font: 400 14px/30px $font-text;
        z-index: 16;
    }

    li {
        cursor: pointer;
        display: block;
        list-style-type: none;
        padding-right: 20px;
        position:relative;
        float:right;
        height:30px;
    }

    .follow-buttons,
    .share-buttons {
        display: none;
    }

    .volume-control {
        cursor: pointer;
        display: inline-block;
        margin: 20px;
    }

    .menu-button {
        padding: 10px;
        position: fixed;
        top: 30px;
        left: 30px;
        cursor: pointer;
        z-index: 16;

        strong {
            color:$gold;
            font: 400 14px/30px $font-text;
            position:absolute;
            top:2px;
            left:40px;
            transition:color 700ms $easeOutExpo 0ms;
        }

        span {
            display: block;
            height: 3px;
            width: 20px;
            background: $gold;

            transition:transform 700ms $easeOutExpo 0ms, opacity 700ms $easeOutExpo 0ms, background 700ms $easeOutExpo 50ms;

            &:nth-child(2) {
                width: 12px;
                margin: 2px 4px;
            }

            &:nth-child(1) {
                transform-origin:0% 50%;
            }

            &:nth-child(3) {
                transform-origin:0% 50%;
            }
        }

        &.active {

            span {
                background: $black;
                transition:transform 700ms $easeOutExpo 0ms, opacity 700ms $easeOutExpo 0ms, background 700ms $easeOutExpo 500ms;

                &:nth-child(1){
                    transform:rotate(45deg) translate3d(0, -3px, 0);
                }

                &:nth-child(2){
                    opacity:0;
                }

                &:nth-child(3){
                    transform:rotate(-45deg) translate3d(0, 3px, 0);
                }
            }

            strong {
                color:$black;
                transition-delay:400ms;
            }

        }
    }

    .home-button {

        padding: 10px;
        position: fixed;
        top: 30px;
        left: 125px;
        cursor: pointer;
        z-index: 16;

        strong {
            color:$gold;
            font: 400 14px/30px $font-text;
            position:absolute;
            top:2px;
            left:0;
            opacity: 0;
        }

        &.active {

            strong {
                color: $black;
                transition:color 2500ms, opacity 2500ms $easeOutExpo 0ms;
                opacity: 1;
            }
        }

    }

    .follow-button {
        span .icon {
            height:7px;
            width:7px;
            top:-2px;
        }
    }

    .dashboard-button {
        top:-2px;

        .icon {
            height:14px;
            width:12px;
            top:2px;
        }
    }

    .share-button {
        span .icon {
            height:11px;
            width:10px;
            top:1px;
        }
    }

    .fb-main-share svg,
    .twtr-main-share svg {
        pointer-events: none;
    }

    // ---------------o Icons

    .mask {
        position:absolute;
        right:10px;
        top:-2px;
        overflow:hidden;
        height:30px;
    }

    ul ul {
        height:100%;
        width:100%;
        background:$gold;

        li {
            float:left;
            padding:0;
            text-align:center;
            line-height:40px;

            a {
                display:block;
                width:40px;
                height:30px;
                position:relative;
            }
        }

        .icon {
            fill:$black;
            height:100%;
            position:absolute;
        }

        .facebook       {width:7px;left:16px;}
        .twitter        {width:19px;left:10px;}
        .tumblr         {width:10px;left:15px;}
        .instagram      {width:16px;left:12px;}
        .youtube        {width:23px;left:8px;}
        .vine           {width:14px;left:13px;}
        .snapchat       {width:16px;left:12px;}
    }

    .share-button .mask {
        width:120px;
    }

    .follow-button .mask {
        width:280px;
    }

    // ---------------o Animations

    li  {
        span {
            transition:transform 700ms $easeOutExpo 300ms, opacity 700ms $easeOutExpo 300ms;
        }
    }

    ul ul {
        transition:transform 1000ms $easeOutExpo 0ms;
        transform:translate3d(calc(100% + 11px), 0, 0);

        .icon {
            transform:translate3d(0, 100%, 0);
            transition:transform 1000ms $easeOutExpo 0ms;
            display:block;
        }
    }

    .follow-button {
        ul {
            li:nth-child(1) .icon         {transition-delay:50ms * 6;}
            li:nth-child(2) .icon         {transition-delay:50ms * 5;}
            li:nth-child(3) .icon         {transition-delay:50ms * 4;}
            li:nth-child(4) .icon         {transition-delay:50ms * 3;}
            li:nth-child(5) .icon         {transition-delay:50ms * 2;}
            li:nth-child(6) .icon         {transition-delay:50ms * 1;}
            li:nth-child(7) .icon         {transition-delay:50ms * 0;}
        }
    }

    .share-button {
        transition:transform 1000ms $easeOutExpo 50ms;
    }

    .follow-button,
    .share-button {
        &:hover {

            ul {
                transform:translate3d(0, 0, 0);
            }
            span {
                opacity:0;
                transform:translate3d(0, -100%, 0);
                transition-delay:0ms;
            }
            .icon {
                transform:translate3d(0, 0, 0);
            }
        }
    }

    .follow-button:hover {
        ul {
            li:nth-child(1) .icon         {transition-delay:50ms * 0 + 100ms;}
            li:nth-child(2) .icon         {transition-delay:50ms * 1 + 100ms;}
            li:nth-child(3) .icon         {transition-delay:50ms * 2 + 100ms;}
            li:nth-child(4) .icon         {transition-delay:50ms * 3 + 100ms;}
            li:nth-child(5) .icon         {transition-delay:50ms * 4 + 100ms;}
            li:nth-child(6) .icon         {transition-delay:50ms * 5 + 100ms;}
            li:nth-child(7) .icon         {transition-delay:50ms * 6 + 100ms;}
        }

        & + .share-button {
            transform:translate3d(-181px, 0, 0);
            transition-delay:0ms;
        }
    }


    @media screen and (min-width:0\0) {
        /* IE9 and IE10 rule sets go here */


        .follow-buttons, .share-buttons, .follow-button, .share-button {
            background: transparent;
        }

        ul {

        }

        ul ul {
            background: transparent;

            &:hover {
                background: $gold;
            }

            .icon {
                //transform: none;
            }
        }

    }



}
