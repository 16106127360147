.about-region {
	overflow:hidden;
	width:100%;

	.center {
		position:relative;
		max-width:1100px;
		width:100%;
		box-sizing:border-box;
		margin:0 auto;
		border-left:1px solid $grey;
		border-right:1px solid $grey;
	}

	h1 {
		font:400 100px/100px $font-big-title;
		color:$gold;
		letter-spacing:1em;
		text-align:center;
		margin:0 auto;
		display:block;
		width:100%;
		height:260px;
		position:fixed;
		left:0;
		z-index:2;
		overflow:hidden;

		position:absolute;
		top:335px;
		margin-top:0;
	}

	.col {
		float:left;
		box-sizing:border-box;
		position:relative;
		overflow:hidden;

		img {
			display:block;
			width:100%;
		}
	}

	.col-small {
		width:percentage(1/3);
		font:400 26px/30px $font-title;
		color:$grey;
		letter-spacing:0.02em;
		text-align:right;

		.pic {
			bottom:0;
		}
	}

	.col-large {
		width:percentage(2/3);
		border-left:1px solid $grey;

		img {
			margin-bottom:230px;
		}
	}

	.content {
		height:850px; // height of cast
		padding-bottom:24px;
		position:relative;
	}

	.part-content {
		box-sizing:border-box;
		padding:0 70px;
		position:absolute;
		top:0;
		width:100%;
		left:0;
	}

	.pic {
		position:absolute;
		overflow:hidden;
	}

	.pic-left {
		right:100%;
		top:240px;
	}

	.pic-right {
		left:100%;
		top:840px;
	}

	.col-large .pic {
		position:relative;
	}


	// -----------------------------o Nav

	.part-nav {
		margin-top:750px;
		padding-right:70px;

		li {
			margin-bottom:20px;
			cursor:pointer;

			span {
				display:inline-block;
			}
		}
	}

	// -----------------------------o About

	.part-about {
		.hat {
			font:400 20px/28px $font-title;
			letter-spacing:0.2em;
			margin-bottom:45px;
		}
	}

	// -----------------------------o Cast

	.part-cast {
		font:400 20px/48px $font-title;
		letter-spacing:0.02em;

		li {
			clear:both;
		}

		p {
			float:left;
			width:50%;
			overflow:hidden;
			position:relative;
		}

		span {
			display:block;
		}

		/*p:nth-child(1) {
			font:500 16px/48px $font-text;
		}*/

		p {
			text-align:center;
			font:400 26px/30px $font-title;
			letter-spacing:0.25em;
			margin-top:81px;
			width:100%;
		}
	}




	// --------------------o Letters


	.letter {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;

		.mask {
			width:100%;
			height:100%;
			position:relative;
			overflow:hidden;
		}

		span {
			display:block;
			position:relative;
		}
	}

	.letter-t span 			{top:0px;left:-278px + 100px;}
	.letter-h span 			{top:0px;left:-100px + 100px;}
	.letter-e span 			{top:0px;left:80px + 100px;}
	.letter-g span 			{top:170px;left:-320px;}
	.letter-r span 			{top:170px;left:-146px;}
	.letter-i span 			{top:170px;left:0px;}
	.letter-n span 			{top:170px;left:147px;}
	.letter-d span 			{top:170px;left:330px;}



	// --------------------o Animations

	.part-nav {
		li {
			transition:color 700ms $easeOutExpo 0ms;

			span {
				border-bottom:1px solid rgba($black, 0);
				transition:border-color 500ms $easeOutExpo 0ms;
			}

			&:hover,
			&.active {
				color:$black;

				span {
					border-bottom:1px solid $black;
				}
			}
		}
	}

	.part-about {
		transition:transform 1500ms $easeOutExpo 300ms;

		&.hidden {
			transform:translate3d(101%, 0, 0);
			transition-delay:0ms;
		}

		&.pre-displayed {
			transform:translate3d(-101%, 0, 0);
			transition-duration:0ms;
		}
	}

	.part-cast {

		p {
			transition:transform 1000ms $easeOutExpo 300ms;
		}

		li {
			span {
				transition:transform 1000ms $easeOutExpo 300ms;
			}

			p:nth-child(2){
				span {
					transition-delay:400ms;
				}
			}
		}


		&.hidden {
			p {
				transform:translate3d(101%, 0, 0);
				transition-delay:0ms;
			}
			li {
				span {
					transform:translate3d(101%, 0, 0);
					transition-delay:0ms;
				}

				p:nth-child(2){
					span {
						transition-delay:0ms;
					}
				}
			}
		}

		&.pre-displayed {
			p {
				transform:translate3d(-101%, 0, 0);
				transition-duration:0ms;
				transition-delay:0ms;
			}

			li {
				span {
					transform:translate3d(-101%, 0, 0);
					transition-duration:0ms;
					transition-delay:0ms;
				}
			}
		}
	}



	// --------------------------------------o Display Transition

	.part-nav {
		transition:transform 1000ms $easeOutExpo 200ms;
	}

	.content {
		transition:transform 1000ms $easeOutExpo 400ms;
	}

	.col-small {
		img {
			transition:transform 1000ms $easeOutExpo 500ms;
		}
	}

	.col-large {
		img {
			transition:transform 1000ms $easeOutExpo 500ms;
		}
	}

	.pic-left {
		img {
			transition:transform 1000ms $easeOutExpo 500ms;
		}
	}

	.pic-right {
		img {
			transition:transform 1000ms $easeOutExpo 600ms;
		}
	}

	&.pre-displayed {

		.part-nav, .content {
			transform:translate3d(-100%, 0, 0);
		}

		.col-small {
			img {
				transform:translate3d(100%, 0, 0);
			}
		}

		.col-large {
			img {
				transform:translate3d(100%, 0, 0);
			}
		}

		.pic-left {
			img {
				transform:translate3d(100%, 0, 0);
			}
		}

		.pic-right {
			img {
				transform:translate3d(100%, 0, 0);
			}
		}

	}

}
