.footer-region {
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    z-index:10;
    letter-spacing:0.1em;

    .icon {
        fill:$gold;
    }

    span {
    	color:$gold;
        cursor:pointer;
    }

    .left {
    	position:absolute;
    	bottom:40px;
    	left:40px;
    	font:400 14px/14px $font-text;
        z-index:2;
    }

    .right {
    	position:absolute;
    	bottom:40px;
    	right:40px;
    	font:500 12px/16px $font-text;

    	a {
    		float:left;
    		margin-left:30px;
    		color:$white;
    	}
    }

    .legal-button {
        .icon {
            height:6px;
            width:10px;
            margin-right:10px;
        }
    }

    .volume-button {
        padding:20px;
        position:relative;
        top:2px;

        .icon {
            height:14px;
            width:16px;
            margin-right:10px;
        }

        .volume-off {
            display:none;
        }

        &.muted {
            .volume-off {display:inline;}
            .volume-on {display:none;}
        }
    }

    .legal-popin {
        position:fixed;
        bottom:0;
        left:0;
        width:100%;
        padding:40px 0;
        display:none;
        transform:translate3d(0, 20px, 0);
        opacity:0;
        transition:transform 1000ms $easeOutExpo 0ms, opacity 1000ms $easeOutExpo 0ms;
        background:$black;
        text-align: center;
        z-index:1;

        img {
            display:block;
            margin:0 auto;
        }

        &.displayed {
            transform:translate3d(0, 0, 0);
            opacity:1;
        }

        p {
            text-align:center;
            margin-top:50px;
        }

        a {
            color:$gold;
            font:400 14px/20px $font-text;
            margin: 10px 20px;
            display: inline-block;
        }

    }

    .copyright-text {
        display: inline-block;
        color: #cbb270;
        font: 400 12px 'Futura', 'Helvetica', sans-serif;
        margin-top: 30px;
    }
}
