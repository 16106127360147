$fontPath : '../fonts';

// fonts are loaded in index file to avoid CORS issues
//@include importFont('BRAGGADO', 'BRAGGADO', 400, normal, $fontPath);
//@include importFont('F37Bella', 'F37Bella', 400, normal, $fontPath);
//@include importFont('Futura', 'FuturaStd-Medium', 500, normal, $fontPath);



// ------------------------------o Fonts

$font-big-title: 'BRAGGADO', 'Helvetica', sans-serif;
$font-title: 'F37Bella', 'Helvetica', sans-serif;
$font-text: 'Futura', 'Helvetica', sans-serif;


// ------------------------------o Colors

$white 				: #FFFFFF;
$black 				: #000000;

$gold 				: #cbb270;
$grey 				: #A7A7A7;


// ------------------------------o UI Colors

$bg-color 				: $white;
$big-title-color 		: $gold;
$title-color 			: $black;


// ------------------------------o Letters size

.letter-a              {width:137px;}
.letter-b              {width:0px;}
.letter-c              {width:130px;}
.letter-d              {width:153px;}
.letter-e              {width:140px;}
.letter-f              {width:0px;}
.letter-g              {width:142px;}
.letter-h              {width:155px;}
.letter-i              {width:87px;}
.letter-j              {width:0px;}
.letter-k              {width:181px;}
.letter-l              {width:150px;}
.letter-m              {width:177px;}
.letter-n              {width:136px;}
.letter-o              {width:155px;}
.letter-p              {width:0px;}
.letter-q              {width:0px;}
.letter-r              {width:166px;}
.letter-s              {width:0px;}
.letter-t              {width:137px;}
.letter-u              {width:0px;}
.letter-v              {width:0px;}
.letter-w              {width:0px;}
.letter-x              {width:0px;}
.letter-y              {width:0px;}
.letter-z              {width:160px;}








// ------------------------------o Breakpoints

$smallScreen: 700px;
$mediumScreenMin: 701px;
$mediumScreen: 1024px;
$largeScreen: 1440px;


// ------------------------------o Moments Photo grid height breakpoints

$smallHeight: 768px;
$mediumHeight: 900px;
