/*
 * A partial implementation of the Ruby list functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/lists.rb
 */
/*
 * A partial implementation of the Ruby constants functions from Compass:
 * https://github.com/Compass/compass/blob/stable/lib/compass/sass_extensions/functions/constants.rb
 */
/*
 * A partial implementation of the Ruby display functions from Compass:
 * https://github.com/Compass/compass/blob/stable/core/lib/compass/core/sass_extensions/functions/display.rb
 */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

.letter-a {
  width: 137px; }

.letter-b {
  width: 0px; }

.letter-c {
  width: 130px; }

.letter-d {
  width: 153px; }

.letter-e {
  width: 140px; }

.letter-f {
  width: 0px; }

.letter-g {
  width: 142px; }

.letter-h {
  width: 155px; }

.letter-i {
  width: 87px; }

.letter-j {
  width: 0px; }

.letter-k {
  width: 181px; }

.letter-l {
  width: 150px; }

.letter-m {
  width: 177px; }

.letter-n {
  width: 136px; }

.letter-o {
  width: 155px; }

.letter-p {
  width: 0px; }

.letter-q {
  width: 0px; }

.letter-r {
  width: 166px; }

.letter-s {
  width: 0px; }

.letter-t {
  width: 137px; }

.letter-u {
  width: 0px; }

.letter-v {
  width: 0px; }

.letter-w {
  width: 0px; }

.letter-x {
  width: 0px; }

.letter-y {
  width: 0px; }

.letter-z {
  width: 160px; }

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  /* For some Androids */ }

body {
  text-transform: uppercase; }
  body.fullscreen {
    position: relative;
    overflow: hidden; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.centered-el {
  position: absolute;
  left: 50%;
  /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%);
  /* % of current element */ }

.svg-icons, .svg-gradients {
  height: 0;
  width: 0;
  position: absolute;
  bottom: -10px;
  left: -10px;
  overflow: hidden; }

.icon {
  fill: blue; }

.gradient-img {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.no-transition {
  transition: all 0ms cubic-bezier(0.25, 0.25, 0.75, 0.75) 0ms !important; }
  .no-transition * {
    transition: all 0ms cubic-bezier(0.25, 0.25, 0.75, 0.75) 0ms !important; }

.text {
  font: 500 16px/28px 'Futura', 'Helvetica', sans-serif;
  color: #A7A7A7;
  text-transform: none;
  -webkit-font-smoothing: antialiased; }
  .text p {
    margin-bottom: 28px; }

.safari .gradient-img {
  display: none !important; }

.portrait-mode, #block-browser {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000 !important;
  height: 100%;
  width: 100%;
  z-index: 1001; }
  .portrait-mode .message-box-ie, #block-browser .message-box-ie {
    margin: 20% auto 0 auto;
    width: 420px; }
  .portrait-mode .message-box, #block-browser .message-box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); }
  .portrait-mode .message-box-button, #block-browser .message-box-button {
    float: right;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 10px; }
    .portrait-mode .message-box-button .button, #block-browser .message-box-button .button {
      width: 180px;
      font: 400 20px/24px 'Futura', 'Helvetica', sans-serif;
      padding: 8px 20px 8px 20px;
      border: 2px solid #cbb270; }
  .portrait-mode .title, #block-browser .title {
    font: 400 26px/26px 'BRAGGADO', 'Helvetica', sans-serif;
    float: left;
    z-index: 4;
    color: #cbb270;
    margin-top: 10px; }
    .portrait-mode .title span, #block-browser .title span {
      display: block;
      font: 400 14px/24px 'F37Bella', 'Helvetica', sans-serif;
      letter-spacing: 0.08em; }
  .portrait-mode span.text, #block-browser span.text {
    float: left;
    color: #cbb270;
    font: 400 20px/24px 'Futura', 'Helvetica', sans-serif;
    letter-spacing: 0.25em; }
    .portrait-mode span.text a, #block-browser span.text a {
      text-decoration: underline;
      color: #cbb270; }

.preloader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background: #000000;
  color: #cbb270;
  font: 400 30px/50px 'BRAGGADO', 'Helvetica', sans-serif; }
  .preloader .logo {
    height: 130px;
    width: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -65px 0 0 -65px; }
  .preloader .letter {
    position: absolute;
    overflow: hidden;
    line-height: 32px;
    height: 30px; }
    .preloader .letter span {
      display: block; }
    .preloader .letter:nth-child(1) {
      top: 0;
      left: 0; }
    .preloader .letter:nth-child(2) {
      top: 0;
      right: 0; }
    .preloader .letter:nth-child(3) {
      top: 50%;
      left: 50%;
      margin: -16px 0 0 -14px; }
    .preloader .letter:nth-child(4) {
      bottom: 0;
      left: 0; }
    .preloader .letter:nth-child(5) {
      bottom: 0;
      right: 0; }
  .preloader .progress {
    height: 2px;
    width: 100%;
    position: absolute;
    overflow: hidden; }
    .preloader .progress span {
      display: block;
      height: 100%;
      width: 100%;
      background: #cbb270;
      transform: translate3d(-100%, 0, 0);
      transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
    .preloader .progress:nth-child(6) {
      top: 39px; }
    .preloader .progress:nth-child(7) {
      top: 89px; }
  .preloader.hidden .letter span {
    transform: translate3d(0, -100%, 0);
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }

.button {
  font: 400 14px/46px 'F37Bella', 'Helvetica', sans-serif;
  border: 1px solid #000000;
  padding: 18px 36px;
  letter-spacing: 0.1em;
  cursor: pointer; }
  .button.button-white {
    border-color: rgba(255, 255, 255, 0.3);
    color: #FFFFFF; }
  .button.button-menu {
    border-color: rgba(255, 255, 255, 0.3);
    font: 500 12px/38px 'Futura', 'Helvetica', sans-serif;
    color: #FFFFFF;
    letter-spacing: 0.2em;
    padding: 0 35px 0 38px; }
  .button.button-gold {
    border-color: rgba(203, 178, 112, 0.3);
    color: #cbb270;
    padding: 5px 85px 5px 88px; }
    .button.button-gold a {
      color: #cbb270; }

.video {
  position: relative; }
  .video .poster, .video .shield {
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0; }
  .video .poster {
    z-index: 4; }
  .video .shield {
    z-index: 6;
    cursor: pointer; }
  .video video, .video .media-container {
    display: block;
    height: 100%;
    width: 100%;
    background: #000000; }
  .video .play-button, .video .pause-button {
    height: 85px;
    width: 85px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -42px 0 0 -42px;
    border: 1px solid #FFFFFF;
    transition: opacity 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    z-index: 5; }
    .video .play-button i, .video .pause-button i {
      display: block;
      position: relative;
      top: 50%;
      left: 50%; }
  .video .pause-button i {
    width: 4px;
    height: 16px;
    margin: -8px 0 0 -2px; }
    .video .pause-button i:before, .video .pause-button i:after {
      content: '';
      display: block;
      height: 100%;
      width: 2px;
      position: absolute;
      background: #FFFFFF; }
    .video .pause-button i:before {
      left: -2px; }
    .video .pause-button i:after {
      right: -2px; }
  .video .play-button i {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 14px;
    border-color: transparent transparent transparent #FFFFFF;
    margin: -8px 0 0 -7px; }
  .video .timeline-container {
    position: absolute;
    bottom: 0;
    height: 20px;
    left: 0;
    width: 100%;
    z-index: 3;
    cursor: pointer; }
    .video .timeline-container .progress, .video .timeline-container .buffer {
      height: 2px;
      width: 0;
      background: #FFFFFF;
      position: absolute;
      bottom: 0;
      left: 0; }
    .video .timeline-container .buffer {
      background: rgba(255, 255, 255, 0.2); }
  .video.playing .play-button, .video.playing .pause-button, .video.paused .play-button, .video.paused .pause-button {
    z-index: 1; }
  .video.playing .poster, .video.paused .poster {
    display: none; }
  .video.playing .shield, .video.paused .shield {
    z-index: 2; }
  .video .pause-button, .video .play-button {
    opacity: 0; }
  .video.hover .pause-button, .video.hover .play-button {
    opacity: 1; }
  .video.hover.playing .play-button {
    opacity: 0; }
  .video.hover.paused .pause-button, .video.hover.stopped .pause-button {
    opacity: 0; }
  .video.stopped .play-button {
    opacity: 1; }

.home-region, .video-region, .about-region, .tumblr-region, .post-region {
  width: 100%; }
  .home-region > div, .video-region > div, .about-region > div, .tumblr-region > div, .post-region > div {
    height: 100%;
    width: 100%; }
  .home-region.fixed, .video-region.fixed, .about-region.fixed, .tumblr-region.fixed, .post-region.fixed {
    position: fixed;
    top: 0;
    z-index: 10; }
  .home-region.hidden .mask, .video-region.hidden .mask, .about-region.hidden .mask, .tumblr-region.hidden .mask, .post-region.hidden .mask {
    overflow: hidden;
    transition: transform 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .home-region.hidden .mask-container, .video-region.hidden .mask-container, .about-region.hidden .mask-container, .tumblr-region.hidden .mask-container, .post-region.hidden .mask-container {
    height: 100%;
    width: 100%;
    transform: translate3d(101%, 0, 0); }
  .home-region.hidden .mask-center, .video-region.hidden .mask-center, .about-region.hidden .mask-center, .tumblr-region.hidden .mask-center, .post-region.hidden .mask-center {
    height: 100%;
    width: 100%;
    transform: translate3d(-91%, 0, 0); }

.tumblr-region.fixed, .post-region.fixed {
  z-index: 7; }

.home-region, .menu-button-container {
  position: relative;
  width: 100%; }
  .home-region .date-message--small, .menu-button-container .date-message--small {
    font-size: 25px;
    line-height: 30px; }
  .home-region .intro, .menu-button-container .intro {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 3; }
    .home-region .intro .intro-video, .menu-button-container .intro .intro-video {
      position: absolute;
      overflow: hidden; }
    .home-region .intro.hidden, .menu-button-container .intro.hidden {
      opacity: 0;
      transition: opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .home-region .home-top, .menu-button-container .home-top {
    display: none;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #000000; }
    .home-region .home-top .background, .menu-button-container .home-top .background {
      position: absolute;
      background: #000000; }
      .home-region .home-top .background img, .menu-button-container .home-top .background img {
        display: block;
        height: 100%;
        width: 100%; }
    .home-region .home-top .bottom-links, .menu-button-container .home-top .bottom-links {
      position: absolute;
      font: 400 15px/15px 'F37Bella', 'Helvetica', sans-serif;
      bottom: 36px;
      right: 40px;
      font: 500 14px/30px 'Futura', 'Helvetica', sans-serif;
      letter-spacing: 0.1em; }
      .home-region .home-top .bottom-links a, .menu-button-container .home-top .bottom-links a {
        margin-left: 30px;
        color: #cbb270;
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        text-align: right;
        border-top: 1px solid #cbb270;
        border-bottom: 1px solid #cbb270;
        white-space: nowrap;
        padding: 0 10px;
        opacity: 0;
        transition: opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
        .home-region .home-top .bottom-links a .icon, .menu-button-container .home-top .bottom-links a .icon {
          fill: #cbb270;
          height: 20px;
          width: 20px;
          position: relative;
          top: 4px;
          margin-right: 10px; }
        .home-region .home-top .bottom-links a.active, .menu-button-container .home-top .bottom-links a.active {
          opacity: 1;
          transition-delay: 300ms;
          z-index: 1; }
    .home-region .home-top .button, .menu-button-container .home-top .button {
      background: rgba(0, 0, 0, 0.5);
      width: 220px;
      padding: 0; }
      .home-region .home-top .button .mask span, .menu-button-container .home-top .button .mask span {
        width: 220px; }
  .home-region .scroll-button, .menu-button-container .scroll-button {
    position: absolute;
    bottom: 0;
    line-height: 90px;
    font: 400 14px/18px 'F37Bella', 'Helvetica', sans-serif;
    color: #cbb270;
    left: 50%;
    margin-left: -100px;
    width: 200px;
    text-align: center;
    letter-spacing: 0.1em;
    cursor: pointer;
    padding: 27px 0; }
    .home-region .scroll-button strong, .menu-button-container .scroll-button strong {
      font-size: 24px;
      display: block; }
  .home-region video, .menu-button-container video {
    height: 100%;
    width: 100%;
    display: block; }
  .home-region .center, .menu-button-container .center {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    color: #cbb270;
    text-align: center;
    width: 100%;
    margin-top: -155px; }
  .home-region h1, .menu-button-container h1 {
    font: 400 140px/122px 'BRAGGADO', 'Helvetica', sans-serif; }
  .home-region h2, .menu-button-container h2 {
    font: 400 32px/40px 'BRAGGADO', 'Helvetica', sans-serif;
    letter-spacing: 0.08em; }
  .home-region h3, .menu-button-container h3 {
    font: 400 45px/50px 'F37Bella', 'Helvetica', sans-serif;
    margin-top: 10px;
    letter-spacing: 0.08em;
    margin-bottom: 60px; }
  .home-region .button, .menu-button-container .button {
    margin: 0 10px;
    position: relative;
    display: inline-block;
    padding: 0 36px;
    height: 56px;
    line-height: 54px;
    border: 1px solid rgba(255, 255, 255, 0.5); }
    .home-region .button p, .menu-button-container .button p {
      position: relative;
      z-index: 2;
      display: block; }
    .home-region .button .bands, .menu-button-container .button .bands {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
      .home-region .button .bands .mask, .menu-button-container .button .bands .mask {
        display: block;
        height: 100%;
        width: 14.5857142857%;
        float: left;
        position: relative;
        overflow: hidden;
        color: #000000;
        position: absolute;
        top: 0; }
        .home-region .button .bands .mask div, .menu-button-container .button .bands .mask div {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden; }
        .home-region .button .bands .mask .out, .menu-button-container .button .bands .mask .out {
          width: 100%; }
        .home-region .button .bands .mask:nth-child(1), .menu-button-container .button .bands .mask:nth-child(1) {
          left: 0%; }
          .home-region .button .bands .mask:nth-child(1) span, .menu-button-container .button .bands .mask:nth-child(1) span {
            margin-left: 0%; }
        .home-region .button .bands .mask:nth-child(2), .menu-button-container .button .bands .mask:nth-child(2) {
          left: 14.2857142857%; }
          .home-region .button .bands .mask:nth-child(2) span, .menu-button-container .button .bands .mask:nth-child(2) span {
            margin-left: -100%; }
        .home-region .button .bands .mask:nth-child(3), .menu-button-container .button .bands .mask:nth-child(3) {
          left: 28.5714285714%; }
          .home-region .button .bands .mask:nth-child(3) span, .menu-button-container .button .bands .mask:nth-child(3) span {
            margin-left: -200%; }
        .home-region .button .bands .mask:nth-child(4), .menu-button-container .button .bands .mask:nth-child(4) {
          left: 42.8571428571%; }
          .home-region .button .bands .mask:nth-child(4) span, .menu-button-container .button .bands .mask:nth-child(4) span {
            margin-left: -300%; }
        .home-region .button .bands .mask:nth-child(5), .menu-button-container .button .bands .mask:nth-child(5) {
          left: 57.1428571429%; }
          .home-region .button .bands .mask:nth-child(5) span, .menu-button-container .button .bands .mask:nth-child(5) span {
            margin-left: -400%; }
        .home-region .button .bands .mask:nth-child(6), .menu-button-container .button .bands .mask:nth-child(6) {
          left: 71.4285714286%; }
          .home-region .button .bands .mask:nth-child(6) span, .menu-button-container .button .bands .mask:nth-child(6) span {
            margin-left: -500%; }
        .home-region .button .bands .mask:nth-child(7), .menu-button-container .button .bands .mask:nth-child(7) {
          left: 85.7142857143%; }
          .home-region .button .bands .mask:nth-child(7) span, .menu-button-container .button .bands .mask:nth-child(7) span {
            margin-left: -600%; }
      .home-region .button .bands span, .menu-button-container .button .bands span {
        display: block;
        height: 100%;
        white-space: nowrap;
        background: #FFFFFF; }
    .home-region .button, .menu-button-container .button {
      transition: border-color 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, background 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
    .home-region .button .bands .mask div, .menu-button-container .button .bands .mask div {
      transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
    .home-region .button .bands .mask .in, .menu-button-container .button .bands .mask .in {
      transform: translate3d(-105%, 0, 0); }
    .home-region .button.displayed, .menu-button-container .button.displayed {
      border-color: rgba(255, 255, 255, 0);
      background: transparent; }
      .home-region .button.displayed .mask .in, .menu-button-container .button.displayed .mask .in {
        transform: translate3d(0, 0, 0); }
      .home-region .button.displayed .mask:nth-child(1) .in, .menu-button-container .button.displayed .mask:nth-child(1) .in {
        transition-delay: 0ms; }
      .home-region .button.displayed .mask:nth-child(2) .in, .menu-button-container .button.displayed .mask:nth-child(2) .in {
        transition-delay: 50ms; }
      .home-region .button.displayed .mask:nth-child(3) .in, .menu-button-container .button.displayed .mask:nth-child(3) .in {
        transition-delay: 100ms; }
      .home-region .button.displayed .mask:nth-child(4) .in, .menu-button-container .button.displayed .mask:nth-child(4) .in {
        transition-delay: 150ms; }
      .home-region .button.displayed .mask:nth-child(5) .in, .menu-button-container .button.displayed .mask:nth-child(5) .in {
        transition-delay: 200ms; }
      .home-region .button.displayed .mask:nth-child(6) .in, .menu-button-container .button.displayed .mask:nth-child(6) .in {
        transition-delay: 250ms; }
      .home-region .button.displayed .mask:nth-child(7) .in, .menu-button-container .button.displayed .mask:nth-child(7) .in {
        transition-delay: 300ms; }
    .home-region .button.hidden .mask .out, .menu-button-container .button.hidden .mask .out {
      transform: translate3d(105%, 0, 0); }
    .home-region .button.hidden .mask .in, .menu-button-container .button.hidden .mask .in {
      transform: translate3d(0, 0, 0); }
    .home-region .button.hidden .mask:nth-child(1) .out, .menu-button-container .button.hidden .mask:nth-child(1) .out {
      transition-delay: 0ms; }
    .home-region .button.hidden .mask:nth-child(2) .out, .menu-button-container .button.hidden .mask:nth-child(2) .out {
      transition-delay: 50ms; }
    .home-region .button.hidden .mask:nth-child(3) .out, .menu-button-container .button.hidden .mask:nth-child(3) .out {
      transition-delay: 100ms; }
    .home-region .button.hidden .mask:nth-child(4) .out, .menu-button-container .button.hidden .mask:nth-child(4) .out {
      transition-delay: 150ms; }
    .home-region .button.hidden .mask:nth-child(5) .out, .menu-button-container .button.hidden .mask:nth-child(5) .out {
      transition-delay: 200ms; }
    .home-region .button.hidden .mask:nth-child(6) .out, .menu-button-container .button.hidden .mask:nth-child(6) .out {
      transition-delay: 250ms; }
    .home-region .button.hidden .mask:nth-child(7) .out, .menu-button-container .button.hidden .mask:nth-child(7) .out {
      transition-delay: 300ms; }
  .home-region h1, .menu-button-container h1 {
    transition: opacity 3000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, transform 3000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .home-region h2, .menu-button-container h2 {
    transition: opacity 3000ms cubic-bezier(0.19, 1, 0.22, 1) 100ms, transform 3000ms cubic-bezier(0.19, 1, 0.22, 1) 100ms; }
  .home-region h3, .menu-button-container h3 {
    transition: opacity 3000ms cubic-bezier(0.19, 1, 0.22, 1) 200ms, transform 3000ms cubic-bezier(0.19, 1, 0.22, 1) 200ms; }
  .home-region a, .menu-button-container a {
    transition: opacity 3000ms cubic-bezier(0.19, 1, 0.22, 1) 300ms, transform 3000ms cubic-bezier(0.19, 1, 0.22, 1) 300ms; }
  .home-region .home-top .background, .menu-button-container .home-top .background {
    transition: opacity 3000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .home-region.pre-displayed h1, .home-region.pre-displayed h2, .home-region.pre-displayed h3, .home-region.pre-displayed a, .menu-button-container.pre-displayed h1, .menu-button-container.pre-displayed h2, .menu-button-container.pre-displayed h3, .menu-button-container.pre-displayed a {
    transform: scale(0.9);
    opacity: 0; }
  .home-region.pre-displayed .home-top .background, .menu-button-container.pre-displayed .home-top .background {
    opacity: 0; }
  .home-region .characters, .menu-button-container .characters {
    position: relative;
    z-index: 3; }
  .home-region .characters-list, .menu-button-container .characters-list {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: none; }
  .home-region .characters-nav, .menu-button-container .characters-nav {
    position: fixed;
    top: 60%;
    left: 10%;
    z-index: 10;
    backface-visibility: hidden; }
    .home-region .characters-nav .nav-progress, .menu-button-container .characters-nav .nav-progress {
      position: absolute;
      top: 80px;
      left: 10px;
      width: 120px;
      color: #cbb270;
      font: 400 18px/32px 'F37Bella', 'Helvetica', sans-serif;
      letter-spacing: 0.02em; }
      .home-region .characters-nav .nav-progress:before, .menu-button-container .characters-nav .nav-progress:before {
        display: block;
        content: '';
        height: 1px;
        width: 35px;
        background: #cbb270;
        position: absolute;
        top: -5px;
        left: 25px; }
    .home-region .characters-nav ul, .menu-button-container .characters-nav ul {
      font: 400 32px/32px 'F37Bella', 'Helvetica', sans-serif;
      height: 32px;
      width: 62px;
      overflow: hidden;
      float: left;
      position: relative;
      margin-right: 7px;
      top: 5px; }
    .home-region .characters-nav li, .menu-button-container .characters-nav li {
      position: absolute;
      right: 0;
      top: 0;
      transition: transform 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
      transform: translate3d(0, -100%, 0); }
      .home-region .characters-nav li.active, .menu-button-container .characters-nav li.active {
        transform: translate3d(0, 0, 0); }
        .home-region .characters-nav li.active ~ li, .menu-button-container .characters-nav li.active ~ li {
          transform: translate3d(0, 100%, 0); }
    .home-region .characters-nav .prev-button, .home-region .characters-nav .next-button, .menu-button-container .characters-nav .prev-button, .menu-button-container .characters-nav .next-button {
      position: absolute;
      top: 0;
      left: -100px;
      transform: rotate(-90deg);
      font: 500 13px/30px 'Futura', 'Helvetica', sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      cursor: pointer; }
    .home-region .characters-nav .next-button, .menu-button-container .characters-nav .next-button {
      top: 100px; }
  .home-region .character-screen, .menu-button-container .character-screen {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4; }
    .home-region .character-screen .background, .menu-button-container .character-screen .background {
      position: absolute;
      display: none; }
    .home-region .character-screen .front, .menu-button-container .character-screen .front {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 1400px;
      max-height: 700px;
      padding: 50px;
      box-sizing: border-box;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 2; }
  .home-region .mask, .menu-button-container .mask {
    position: relative;
    overflow: hidden; }
  .home-region .character-name, .menu-button-container .character-name {
    font: 400 150px/170px 'BRAGGADO', 'Helvetica', sans-serif;
    letter-spacing: 0.4em;
    color: #cbb270;
    text-align: center;
    position: absolute; }
    .home-region .character-name .letter, .menu-button-container .character-name .letter {
      display: inline-block;
      text-align: left; }
      .home-region .character-name .letter div, .menu-button-container .character-name .letter div {
        width: 200%;
        overflow: hidden;
        position: relative; }
    .home-region .character-name span, .menu-button-container .character-name span {
      display: block;
      width: 100%; }
  .home-region .character-post-title, .menu-button-container .character-post-title {
    letter-spacing: 0.2em;
    position: absolute;
    overflow: hidden; }
    .home-region .character-post-title div strong, .menu-button-container .character-post-title div strong {
      color: #cbb270;
      font: 400 28px/28px 'BRAGGADO', 'Helvetica', sans-serif;
      margin-right: 10px; }
    .home-region .character-post-title div span, .menu-button-container .character-post-title div span {
      color: #cbb270;
      font: 400 30px/30px 'F37Bella', 'Helvetica', sans-serif; }
    .home-region .character-post-title p strong, .menu-button-container .character-post-title p strong {
      display: block;
      font: 400 18px/30px 'Futura', 'Helvetica', sans-serif; }
    .home-region .character-post-title p span, .menu-button-container .character-post-title p span {
      font: 400 30px/30px 'F37Bella', 'Helvetica', sans-serif; }
  .home-region .character-infos, .menu-button-container .character-infos {
    position: absolute; }
    .home-region .character-infos .title, .menu-button-container .character-infos .title {
      display: none;
      color: #cbb270;
      font: 500 13px/30px 'Futura', 'Helvetica', sans-serif;
      margin-bottom: 15px;
      overflow: hidden; }
    .home-region .character-infos .quote, .menu-button-container .character-infos .quote {
      font: 400 30px/40px 'F37Bella', 'Helvetica', sans-serif;
      letter-spacing: 0.1em;
      margin-bottom: 25px;
      overflow: hidden; }
    .home-region .character-infos .button, .menu-button-container .character-infos .button {
      width: 225px;
      text-align: center;
      border: 1px solid rgba(203, 178, 112, 0.5); }
      .home-region .character-infos .button .mask span, .menu-button-container .character-infos .button .mask span {
        background: #cbb270;
        color: #FFFFFF;
        width: 225px; }
  .home-region .button-more, .menu-button-container .button-more {
    position: absolute;
    overflow: hidden;
    width: 221px;
    height: 302px; }
    .home-region .button-more .button, .menu-button-container .button-more .button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      white-space: nowrap;
      margin: 0; }
    .home-region .button-more img, .menu-button-container .button-more img {
      display: block;
      height: 100%;
      width: 100%; }
  .home-region .button-download, .menu-button-container .button-download {
    position: absolute;
    overflow: hidden; }
    .home-region .button-download p, .menu-button-container .button-download p {
      font: 500 12px/20px 'Futura', 'Helvetica', sans-serif;
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;
      text-align: center;
      color: #FFFFFF;
      letter-spacing: 0.2em;
      box-sizing: border-box;
      padding: 0 40px; }
  .home-region .characters-list .characters-nav, .menu-button-container .characters-list .characters-nav {
    transition: opacity 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    opacity: 0; }
  .home-region .characters-list.displayed .characters-nav, .menu-button-container .characters-list.displayed .characters-nav {
    transition-delay: 2000ms;
    opacity: 1; }
  .home-region .transitions-container, .menu-button-container .transitions-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2; }
    .home-region .transitions-container.backgrounded, .menu-button-container .transitions-container.backgrounded {
      background: #FFFFFF; }
    .home-region .transitions-container img, .menu-button-container .transitions-container img {
      backface-visibility: hidden; }
  .home-region .bands-container, .menu-button-container .bands-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    perspective: 2000px;
    transform-style: preserve-3d; }
    .home-region .bands-container .mask, .menu-button-container .bands-container .mask {
      position: relative;
      width: 1000%;
      height: 100%;
      transition: transform 2400ms cubic-bezier(0.77, 0, 0.175, 1) 0ms; }
    .home-region .bands-container .band, .menu-button-container .bands-container .band {
      position: absolute;
      height: 100%;
      width: 10.1%;
      float: left;
      overflow: hidden;
      transition: transform 3000ms cubic-bezier(0.23, 1, 0.32, 1) 3000ms;
      backface-visibility: hidden; }
      .home-region .bands-container .band:nth-child(1), .menu-button-container .bands-container .band:nth-child(1) {
        left: 0%;
        transition-delay: 0ms; }
        .home-region .bands-container .band:nth-child(1) .mask, .menu-button-container .bands-container .band:nth-child(1) .mask {
          left: 0%;
          transition-delay: 0ms; }
      .home-region .bands-container .band:nth-child(2), .menu-button-container .bands-container .band:nth-child(2) {
        left: 10%;
        transition-delay: 100ms; }
        .home-region .bands-container .band:nth-child(2) .mask, .menu-button-container .bands-container .band:nth-child(2) .mask {
          left: -100%;
          transition-delay: 100ms; }
      .home-region .bands-container .band:nth-child(3), .menu-button-container .bands-container .band:nth-child(3) {
        left: 20%;
        transition-delay: 200ms; }
        .home-region .bands-container .band:nth-child(3) .mask, .menu-button-container .bands-container .band:nth-child(3) .mask {
          left: -200%;
          transition-delay: 200ms; }
      .home-region .bands-container .band:nth-child(4), .menu-button-container .bands-container .band:nth-child(4) {
        left: 30%;
        transition-delay: 300ms; }
        .home-region .bands-container .band:nth-child(4) .mask, .menu-button-container .bands-container .band:nth-child(4) .mask {
          left: -300%;
          transition-delay: 300ms; }
      .home-region .bands-container .band:nth-child(5), .menu-button-container .bands-container .band:nth-child(5) {
        left: 40%;
        transition-delay: 400ms; }
        .home-region .bands-container .band:nth-child(5) .mask, .menu-button-container .bands-container .band:nth-child(5) .mask {
          left: -400%;
          transition-delay: 400ms; }
      .home-region .bands-container .band:nth-child(6), .menu-button-container .bands-container .band:nth-child(6) {
        left: 50%;
        transition-delay: 500ms; }
        .home-region .bands-container .band:nth-child(6) .mask, .menu-button-container .bands-container .band:nth-child(6) .mask {
          left: -500%;
          transition-delay: 500ms; }
      .home-region .bands-container .band:nth-child(7), .menu-button-container .bands-container .band:nth-child(7) {
        left: 60%;
        transition-delay: 600ms; }
        .home-region .bands-container .band:nth-child(7) .mask, .menu-button-container .bands-container .band:nth-child(7) .mask {
          left: -600%;
          transition-delay: 600ms; }
      .home-region .bands-container .band:nth-child(8), .menu-button-container .bands-container .band:nth-child(8) {
        left: 70%;
        transition-delay: 700ms; }
        .home-region .bands-container .band:nth-child(8) .mask, .menu-button-container .bands-container .band:nth-child(8) .mask {
          left: -700%;
          transition-delay: 700ms; }
      .home-region .bands-container .band:nth-child(9), .menu-button-container .bands-container .band:nth-child(9) {
        left: 80%;
        transition-delay: 800ms; }
        .home-region .bands-container .band:nth-child(9) .mask, .menu-button-container .bands-container .band:nth-child(9) .mask {
          left: -800%;
          transition-delay: 800ms; }
      .home-region .bands-container .band:nth-child(10), .menu-button-container .bands-container .band:nth-child(10) {
        left: 90%;
        transition-delay: 900ms; }
        .home-region .bands-container .band:nth-child(10) .mask, .menu-button-container .bands-container .band:nth-child(10) .mask {
          left: -900%;
          transition-delay: 900ms; }
      .home-region .bands-container .band:nth-child(11), .menu-button-container .bands-container .band:nth-child(11) {
        left: 100%;
        transition-delay: 1000ms; }
        .home-region .bands-container .band:nth-child(11) .mask, .menu-button-container .bands-container .band:nth-child(11) .mask {
          left: -1000%;
          transition-delay: 1000ms; }
    .home-region .bands-container img, .menu-button-container .bands-container img {
      display: block;
      position: absolute; }
  .home-region .character-screen, .menu-button-container .character-screen {
    display: none; }
  .home-region .bands-container.leaving > .background, .home-region .bands-container.pre-displayed > .background, .menu-button-container .bands-container.leaving > .background, .menu-button-container .bands-container.pre-displayed > .background {
    display: none; }
  .home-region .bands-container.leaving .mask, .menu-button-container .bands-container.leaving .mask {
    transform: translate3d(20%, 0, 0);
    transition: transform 3000ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
  .home-region .bands-container.leaving .band, .menu-button-container .bands-container.leaving .band {
    transform: rotateY(180deg); }
  .home-region .bands-container.pre-displayed .mask, .menu-button-container .bands-container.pre-displayed .mask {
    transform: translate3d(-20%, 0, 0); }
  .home-region .bands-container.pre-displayed .band, .menu-button-container .bands-container.pre-displayed .band {
    transform: rotateY(-180deg); }
  .home-region .character-screen .mask, .menu-button-container .character-screen .mask {
    transition: transform 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .home-region .character-screen .letter span, .menu-button-container .character-screen .letter span {
    transition: transform 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .home-region .character-infos .button, .menu-button-container .character-infos .button {
    transition: opacity 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .home-region .character-screen.leaving .mask, .menu-button-container .character-screen.leaving .mask {
    transform: translate3d(101%, 0, 0); }
  .home-region .character-screen.leaving .letter .mask, .menu-button-container .character-screen.leaving .letter .mask {
    transform: translate3d(0, 0, 0); }
    .home-region .character-screen.leaving .letter .mask span, .menu-button-container .character-screen.leaving .letter .mask span {
      transform: translate3d(101%, 0, 0); }
  .home-region .character-screen.leaving .character-post-title .mask:nth-child(1), .menu-button-container .character-screen.leaving .character-post-title .mask:nth-child(1) {
    transition-delay: 0ms; }
  .home-region .character-screen.leaving .character-post-title .mask:nth-child(2), .menu-button-container .character-screen.leaving .character-post-title .mask:nth-child(2) {
    transition-delay: 0ms; }
  .home-region .character-screen.leaving .button-more .mask, .menu-button-container .character-screen.leaving .button-more .mask {
    transition-delay: 0ms; }
  .home-region .character-screen.leaving .button-download .mask, .menu-button-container .character-screen.leaving .button-download .mask {
    transition-delay: 200ms; }
  .home-region .character-screen.leaving .character-name .letter:nth-child(1) span, .menu-button-container .character-screen.leaving .character-name .letter:nth-child(1) span {
    transition-delay: 100ms; }
  .home-region .character-screen.leaving .character-name .letter:nth-child(2) span, .menu-button-container .character-screen.leaving .character-name .letter:nth-child(2) span {
    transition-delay: 200ms; }
  .home-region .character-screen.leaving .character-name .letter:nth-child(3) span, .menu-button-container .character-screen.leaving .character-name .letter:nth-child(3) span {
    transition-delay: 300ms; }
  .home-region .character-screen.leaving .character-name .letter:nth-child(4) span, .menu-button-container .character-screen.leaving .character-name .letter:nth-child(4) span {
    transition-delay: 400ms; }
  .home-region .character-screen.leaving .character-name .letter:nth-child(5) span, .menu-button-container .character-screen.leaving .character-name .letter:nth-child(5) span {
    transition-delay: 500ms; }
  .home-region .character-screen.leaving .character-name .letter:nth-child(6) span, .menu-button-container .character-screen.leaving .character-name .letter:nth-child(6) span {
    transition-delay: 600ms; }
  .home-region .character-screen.leaving .character-name .letter:nth-child(7) span, .menu-button-container .character-screen.leaving .character-name .letter:nth-child(7) span {
    transition-delay: 700ms; }
  .home-region .character-screen.leaving .character-name .letter:nth-child(8) span, .menu-button-container .character-screen.leaving .character-name .letter:nth-child(8) span {
    transition-delay: 800ms; }
  .home-region .character-screen.leaving .character-name .letter:nth-child(9) span, .menu-button-container .character-screen.leaving .character-name .letter:nth-child(9) span {
    transition-delay: 900ms; }
  .home-region .character-screen.leaving .quote .mask:nth-child(1), .menu-button-container .character-screen.leaving .quote .mask:nth-child(1) {
    transition-delay: 100ms; }
  .home-region .character-screen.leaving .quote .mask:nth-child(2), .menu-button-container .character-screen.leaving .quote .mask:nth-child(2) {
    transition-delay: 200ms; }
  .home-region .character-screen.leaving .quote .mask:nth-child(3), .menu-button-container .character-screen.leaving .quote .mask:nth-child(3) {
    transition-delay: 300ms; }
  .home-region .character-screen.leaving .quote .mask:nth-child(4), .menu-button-container .character-screen.leaving .quote .mask:nth-child(4) {
    transition-delay: 400ms; }
  .home-region .character-screen.leaving .quote .mask:nth-child(5), .menu-button-container .character-screen.leaving .quote .mask:nth-child(5) {
    transition-delay: 500ms; }
  .home-region .character-screen.leaving .character-infos .button, .menu-button-container .character-screen.leaving .character-infos .button {
    opacity: 0; }
  .home-region .character-screen.pre-displayed .mask, .menu-button-container .character-screen.pre-displayed .mask {
    transform: translate3d(-101%, 0, 0); }
  .home-region .character-screen.pre-displayed .letter .mask, .menu-button-container .character-screen.pre-displayed .letter .mask {
    transform: translate3d(0, 0, 0); }
    .home-region .character-screen.pre-displayed .letter .mask span, .menu-button-container .character-screen.pre-displayed .letter .mask span {
      transform: translate3d(-101%, 0, 0); }
  .home-region .character-screen.pre-displayed .character-infos .button, .menu-button-container .character-screen.pre-displayed .character-infos .button {
    opacity: 0; }
  .home-region .character-screen.displayed .button-more .mask, .menu-button-container .character-screen.displayed .button-more .mask {
    transition-delay: 1000ms; }
  .home-region .character-screen.displayed .button-download .mask, .menu-button-container .character-screen.displayed .button-download .mask {
    transition-delay: 1200ms; }
  .home-region .character-screen.displayed .character-name .letter:nth-child(1) span, .menu-button-container .character-screen.displayed .character-name .letter:nth-child(1) span {
    transition-delay: 1550ms; }
  .home-region .character-screen.displayed .character-name .letter:nth-child(2) span, .menu-button-container .character-screen.displayed .character-name .letter:nth-child(2) span {
    transition-delay: 1600ms; }
  .home-region .character-screen.displayed .character-name .letter:nth-child(3) span, .menu-button-container .character-screen.displayed .character-name .letter:nth-child(3) span {
    transition-delay: 1650ms; }
  .home-region .character-screen.displayed .character-name .letter:nth-child(4) span, .menu-button-container .character-screen.displayed .character-name .letter:nth-child(4) span {
    transition-delay: 1700ms; }
  .home-region .character-screen.displayed .character-name .letter:nth-child(5) span, .menu-button-container .character-screen.displayed .character-name .letter:nth-child(5) span {
    transition-delay: 1750ms; }
  .home-region .character-screen.displayed .character-name .letter:nth-child(6) span, .menu-button-container .character-screen.displayed .character-name .letter:nth-child(6) span {
    transition-delay: 1800ms; }
  .home-region .character-screen.displayed .character-name .letter:nth-child(7) span, .menu-button-container .character-screen.displayed .character-name .letter:nth-child(7) span {
    transition-delay: 1850ms; }
  .home-region .character-screen.displayed .character-name .letter:nth-child(8) span, .menu-button-container .character-screen.displayed .character-name .letter:nth-child(8) span {
    transition-delay: 1900ms; }
  .home-region .character-screen.displayed .character-name .letter:nth-child(9) span, .menu-button-container .character-screen.displayed .character-name .letter:nth-child(9) span {
    transition-delay: 1950ms; }
  .home-region .character-screen.displayed .quote .mask:nth-child(1), .menu-button-container .character-screen.displayed .quote .mask:nth-child(1) {
    transition-delay: 1100ms; }
  .home-region .character-screen.displayed .quote .mask:nth-child(2), .menu-button-container .character-screen.displayed .quote .mask:nth-child(2) {
    transition-delay: 1200ms; }
  .home-region .character-screen.displayed .quote .mask:nth-child(3), .menu-button-container .character-screen.displayed .quote .mask:nth-child(3) {
    transition-delay: 1300ms; }
  .home-region .character-screen.displayed .quote .mask:nth-child(4), .menu-button-container .character-screen.displayed .quote .mask:nth-child(4) {
    transition-delay: 1400ms; }
  .home-region .character-screen.displayed .quote .mask:nth-child(5), .menu-button-container .character-screen.displayed .quote .mask:nth-child(5) {
    transition-delay: 1500ms; }
  .home-region .character-screen.displayed .character-infos .title .mask, .menu-button-container .character-screen.displayed .character-infos .title .mask {
    transition-delay: 950ms; }
  .home-region .character-screen.displayed .character-infos .button, .menu-button-container .character-screen.displayed .character-infos .button {
    transition-delay: 2000ms; }
  .home-region .character-screen.pre-displayed .character-post-title .mask, .home-region .character-screen.displayed .character-post-title .mask, .menu-button-container .character-screen.pre-displayed .character-post-title .mask, .menu-button-container .character-screen.displayed .character-post-title .mask {
    transform: translate3d(-100%, 0, 0); }
  .home-region .character-screen.post-displayed .letter .mask, .menu-button-container .character-screen.post-displayed .letter .mask {
    transform: translate3d(0, 0, 0); }
    .home-region .character-screen.post-displayed .letter .mask span, .menu-button-container .character-screen.post-displayed .letter .mask span {
      transform: translate3d(101%, 0, 0); }
  .home-region .character-screen.post-displayed .character-post-title .mask:nth-child(1), .menu-button-container .character-screen.post-displayed .character-post-title .mask:nth-child(1) {
    transition-delay: 1000ms; }
  .home-region .character-screen.post-displayed .character-post-title .mask:nth-child(2), .menu-button-container .character-screen.post-displayed .character-post-title .mask:nth-child(2) {
    transition-delay: 1100ms; }
  .home-region .character-mike .character-name, .menu-button-container .character-mike .character-name {
    width: 630px;
    top: 50%;
    left: 50%;
    margin: -390px 0 0 -315px; }
  .home-region .character-mike .character-post-title, .menu-button-container .character-mike .character-post-title {
    top: 80px;
    right: 50px;
    text-align: right; }
    @media screen and (min-width: 1920px) {
      .home-region .character-mike .character-post-title, .menu-button-container .character-mike .character-post-title {
        right: -170px; } }
    @media screen and (min-width: 2200px) {
      .home-region .character-mike .character-post-title, .menu-button-container .character-mike .character-post-title {
        right: -270px; } }
  .home-region .character-mike .character-infos, .menu-button-container .character-mike .character-infos {
    width: 250px;
    text-align: right;
    bottom: 30px;
    right: 30px; }
    @media screen and (min-width: 1920px) {
      .home-region .character-mike .character-infos, .menu-button-container .character-mike .character-infos {
        right: -170px; } }
    @media screen and (min-width: 2200px) {
      .home-region .character-mike .character-infos, .menu-button-container .character-mike .character-infos {
        right: -270px; } }
  .home-region .character-mike .button-download, .menu-button-container .character-mike .button-download {
    top: 50%;
    margin-top: -200px;
    left: 70px; }
  .home-region .character-ken .character-name, .menu-button-container .character-ken .character-name {
    width: 260px;
    top: 50%;
    left: 50%;
    margin: -330px 0 0 100px; }
    .home-region .character-ken .character-name .letter:nth-child(2), .menu-button-container .character-ken .character-name .letter:nth-child(2) {
      margin-left: 200px; }
  .home-region .character-ken .character-post-title, .menu-button-container .character-ken .character-post-title {
    left: 50%;
    top: 50%;
    margin: 10% 0 0 -13%; }
  .home-region .character-ken .character-infos, .menu-button-container .character-ken .character-infos {
    width: 250px;
    top: 20%;
    left: 70px; }
  .home-region .character-ken .button-download, .menu-button-container .character-ken .button-download {
    left: 50%;
    top: 50%;
    margin: -20% 0 0 -13%; }
  .home-region .character-richie .character-name, .menu-button-container .character-richie .character-name {
    width: 800px;
    top: 50%;
    left: 50%;
    margin: -330px 0 0 -400px; }
    .home-region .character-richie .character-name .letter:nth-child(3), .menu-button-container .character-richie .character-name .letter:nth-child(3) {
      margin-left: 350px; }
  .home-region .character-richie .character-post-title, .menu-button-container .character-richie .character-post-title {
    top: 80px;
    right: 50px;
    text-align: right; }
  .home-region .character-richie .character-infos, .menu-button-container .character-richie .character-infos {
    left: 43%;
    top: 50%;
    margin: -20% 0 0 -40%;
    width: 300px; }
  .home-region .character-richie .button-download, .menu-button-container .character-richie .button-download {
    bottom: 20px;
    left: 23%; }
    .home-region .character-richie .button-download p, .menu-button-container .character-richie .button-download p {
      bottom: 12px; }
  .home-region .character-tito .character-name, .menu-button-container .character-tito .character-name {
    width: 800px;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -400px; }
    .home-region .character-tito .character-name .letter:nth-child(3), .menu-button-container .character-tito .character-name .letter:nth-child(3) {
      margin-left: 250px; }
  .home-region .character-tito .character-post-title, .menu-button-container .character-tito .character-post-title {
    bottom: 80px;
    right: 30px;
    text-align: right; }
  .home-region .character-tito .character-infos, .menu-button-container .character-tito .character-infos {
    top: 55%;
    right: 44%;
    width: 450px;
    margin: -30% -12% 0 0;
    width: 450px;
    text-align: right; }
  .home-region .character-tito .button-download, .menu-button-container .character-tito .button-download {
    right: 30px;
    top: 80px; }
  .home-region .character-tarzan .character-name, .menu-button-container .character-tarzan .character-name {
    width: 800px;
    top: 50%;
    left: 50%;
    margin: -275px 0 0 -400px; }
    .home-region .character-tarzan .character-name .letter:nth-child(3), .menu-button-container .character-tarzan .character-name .letter:nth-child(3) {
      margin-left: 250px; }
  .home-region .character-tarzan .character-post-title, .menu-button-container .character-tarzan .character-post-title {
    bottom: 320px;
    right: 27px;
    text-align: right; }
  .home-region .character-tarzan .character-infos, .menu-button-container .character-tarzan .character-infos {
    width: 450px;
    text-align: right;
    right: 30px;
    bottom: 30px; }
  .home-region .character-tarzan .button-download, .menu-button-container .character-tarzan .button-download {
    left: 80px;
    top: 50%;
    margin-top: -250px; }
  .home-region .character-rome .character-name, .menu-button-container .character-rome .character-name {
    width: 800px;
    top: 50%;
    left: 50%;
    margin: -250px 0 0 -500px; }
    .home-region .character-rome .character-name .letter:nth-child(3), .menu-button-container .character-rome .character-name .letter:nth-child(3) {
      margin-left: 250px; }
  .home-region .character-rome .character-post-title, .menu-button-container .character-rome .character-post-title {
    top: 100px;
    left: 30px; }
  .home-region .character-rome .character-infos, .menu-button-container .character-rome .character-infos {
    width: 450px;
    text-align: right;
    text-align: right;
    right: 30px;
    bottom: 30px; }
  .home-region .character-rome .button-download, .menu-button-container .character-rome .button-download {
    right: 30px;
    top: 70px; }
  .home-region .character-malick .character-name, .menu-button-container .character-malick .character-name {
    width: 800px;
    top: 50%;
    left: 50%;
    margin: -290px 0 0 -420px; }
    .home-region .character-malick .character-name .letter:nth-child(3), .menu-button-container .character-malick .character-name .letter:nth-child(3) {
      margin-left: 250px; }
  .home-region .character-malick .character-post-title, .menu-button-container .character-malick .character-post-title {
    right: 40px;
    top: 130px;
    text-align: right; }
  .home-region .character-malick .character-infos, .menu-button-container .character-malick .character-infos {
    width: 450px;
    text-align: right;
    right: 30px;
    top: 170px; }
  .home-region .character-malick .button-download, .menu-button-container .character-malick .button-download {
    top: 50%;
    margin-top: -200px;
    left: 80px; }
  .home-region .character-andre .character-name, .menu-button-container .character-andre .character-name {
    width: 800px;
    top: 50%;
    left: 50%;
    margin: -290px 0 0 -380px; }
    .home-region .character-andre .character-name .letter:nth-child(3), .menu-button-container .character-andre .character-name .letter:nth-child(3) {
      margin-left: 250px;
      margin-right: 250px; }
  .home-region .character-andre .character-post-title, .menu-button-container .character-andre .character-post-title {
    bottom: 30px;
    right: 30px;
    text-align: right; }
    @media screen and (min-width: 1920px) {
      .home-region .character-andre .character-post-title, .menu-button-container .character-andre .character-post-title {
        right: -170px; } }
  .home-region .character-andre .character-infos, .menu-button-container .character-andre .character-infos {
    width: 450px;
    text-align: right;
    right: 30px;
    top: 50%;
    margin-top: -20%; }
    @media screen and (min-width: 1920px) {
      .home-region .character-andre .character-infos, .menu-button-container .character-andre .character-infos {
        right: -170px; } }
  .home-region .character-andre .button-download, .menu-button-container .character-andre .button-download {
    top: 50%;
    margin-top: -200px;
    left: 80px; }
  .home-region .MPAA_requirements, .menu-button-container .MPAA_requirements {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    position: absolute;
    bottom: 100px;
    background: rgba(0, 0, 0, 0.75);
    width: 180px;
    text-align: center;
    padding: 10px; }
    .home-region .MPAA_requirements.hide, .menu-button-container .MPAA_requirements.hide {
      -webkit-transform: translateX(-180px);
      -moz-transform: translateX(-180px);
      -ms-transform: translateX(-180px);
      -o-transform: translateX(-180px);
      transform: translateX(-180px); }
    .home-region .MPAA_requirements li, .menu-button-container .MPAA_requirements li {
      font-family: 'Futura', 'Helvetica', sans-serif;
      padding: 8px 0;
      font-size: 14px; }
      .home-region .MPAA_requirements li a, .menu-button-container .MPAA_requirements li a {
        color: #cbb270; }

.home-region .character-name .safari-show {
  display: none; }

.safari .home-region .character-name .safari-show {
  display: block; }
.safari .home-region .character-name .safari-hide {
  display: none; }

.touch .home-region h1 {
  font-size: 94px;
  line-height: 94px;
  margin-bottom: 30px;
  margin-top: 50px; }

.header-region {
  width: 100%;
  letter-spacing: 0.1em; }
  .header-region a, .header-region span {
    display: block;
    color: #cbb270; }
  .header-region span .icon {
    fill: #cbb270;
    position: relative;
    margin-right: 8px; }
  .header-region .social-buttons {
    position: fixed;
    top: 38px;
    right: 40px;
    font: 400 14px/30px 'Futura', 'Helvetica', sans-serif;
    z-index: 16; }
  .header-region li {
    cursor: pointer;
    display: block;
    list-style-type: none;
    padding-right: 20px;
    position: relative;
    float: right;
    height: 30px; }
  .header-region .follow-buttons, .header-region .share-buttons {
    display: none; }
  .header-region .volume-control {
    cursor: pointer;
    display: inline-block;
    margin: 20px; }
  .header-region .menu-button {
    padding: 10px;
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
    z-index: 16; }
    .header-region .menu-button strong {
      color: #cbb270;
      font: 400 14px/30px 'Futura', 'Helvetica', sans-serif;
      position: absolute;
      top: 2px;
      left: 40px;
      transition: color 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
    .header-region .menu-button span {
      display: block;
      height: 3px;
      width: 20px;
      background: #cbb270;
      transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, opacity 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, background 700ms cubic-bezier(0.19, 1, 0.22, 1) 50ms; }
      .header-region .menu-button span:nth-child(2) {
        width: 12px;
        margin: 2px 4px; }
      .header-region .menu-button span:nth-child(1) {
        transform-origin: 0% 50%; }
      .header-region .menu-button span:nth-child(3) {
        transform-origin: 0% 50%; }
    .header-region .menu-button.active span {
      background: #000000;
      transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, opacity 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, background 700ms cubic-bezier(0.19, 1, 0.22, 1) 500ms; }
      .header-region .menu-button.active span:nth-child(1) {
        transform: rotate(45deg) translate3d(0, -3px, 0); }
      .header-region .menu-button.active span:nth-child(2) {
        opacity: 0; }
      .header-region .menu-button.active span:nth-child(3) {
        transform: rotate(-45deg) translate3d(0, 3px, 0); }
    .header-region .menu-button.active strong {
      color: #000000;
      transition-delay: 400ms; }
  .header-region .home-button {
    padding: 10px;
    position: fixed;
    top: 30px;
    left: 125px;
    cursor: pointer;
    z-index: 16; }
    .header-region .home-button strong {
      color: #cbb270;
      font: 400 14px/30px 'Futura', 'Helvetica', sans-serif;
      position: absolute;
      top: 2px;
      left: 0;
      opacity: 0; }
    .header-region .home-button.active strong {
      color: #000000;
      transition: color 2500ms, opacity 2500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
      opacity: 1; }
  .header-region .follow-button span .icon {
    height: 7px;
    width: 7px;
    top: -2px; }
  .header-region .dashboard-button {
    top: -2px; }
    .header-region .dashboard-button .icon {
      height: 14px;
      width: 12px;
      top: 2px; }
  .header-region .share-button span .icon {
    height: 11px;
    width: 10px;
    top: 1px; }
  .header-region .fb-main-share svg, .header-region .twtr-main-share svg {
    pointer-events: none; }
  .header-region .mask {
    position: absolute;
    right: 10px;
    top: -2px;
    overflow: hidden;
    height: 30px; }
  .header-region ul ul {
    height: 100%;
    width: 100%;
    background: #cbb270; }
    .header-region ul ul li {
      float: left;
      padding: 0;
      text-align: center;
      line-height: 40px; }
      .header-region ul ul li a {
        display: block;
        width: 40px;
        height: 30px;
        position: relative; }
    .header-region ul ul .icon {
      fill: #000000;
      height: 100%;
      position: absolute; }
    .header-region ul ul .facebook {
      width: 7px;
      left: 16px; }
    .header-region ul ul .twitter {
      width: 19px;
      left: 10px; }
    .header-region ul ul .tumblr {
      width: 10px;
      left: 15px; }
    .header-region ul ul .instagram {
      width: 16px;
      left: 12px; }
    .header-region ul ul .youtube {
      width: 23px;
      left: 8px; }
    .header-region ul ul .vine {
      width: 14px;
      left: 13px; }
    .header-region ul ul .snapchat {
      width: 16px;
      left: 12px; }
  .header-region .share-button .mask {
    width: 120px; }
  .header-region .follow-button .mask {
    width: 280px; }
  .header-region li span {
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1) 300ms, opacity 700ms cubic-bezier(0.19, 1, 0.22, 1) 300ms; }
  .header-region ul ul {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    transform: translate3d(calc(100% + 11px), 0, 0); }
    .header-region ul ul .icon {
      transform: translate3d(0, 100%, 0);
      transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
      display: block; }
  .header-region .follow-button ul li:nth-child(1) .icon {
    transition-delay: 300ms; }
  .header-region .follow-button ul li:nth-child(2) .icon {
    transition-delay: 250ms; }
  .header-region .follow-button ul li:nth-child(3) .icon {
    transition-delay: 200ms; }
  .header-region .follow-button ul li:nth-child(4) .icon {
    transition-delay: 150ms; }
  .header-region .follow-button ul li:nth-child(5) .icon {
    transition-delay: 100ms; }
  .header-region .follow-button ul li:nth-child(6) .icon {
    transition-delay: 50ms; }
  .header-region .follow-button ul li:nth-child(7) .icon {
    transition-delay: 0ms; }
  .header-region .share-button {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 50ms; }
  .header-region .follow-button:hover ul, .header-region .share-button:hover ul {
    transform: translate3d(0, 0, 0); }
  .header-region .follow-button:hover span, .header-region .share-button:hover span {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
    transition-delay: 0ms; }
  .header-region .follow-button:hover .icon, .header-region .share-button:hover .icon {
    transform: translate3d(0, 0, 0); }
  .header-region .follow-button:hover ul li:nth-child(1) .icon {
    transition-delay: 100ms; }
  .header-region .follow-button:hover ul li:nth-child(2) .icon {
    transition-delay: 150ms; }
  .header-region .follow-button:hover ul li:nth-child(3) .icon {
    transition-delay: 200ms; }
  .header-region .follow-button:hover ul li:nth-child(4) .icon {
    transition-delay: 250ms; }
  .header-region .follow-button:hover ul li:nth-child(5) .icon {
    transition-delay: 300ms; }
  .header-region .follow-button:hover ul li:nth-child(6) .icon {
    transition-delay: 350ms; }
  .header-region .follow-button:hover ul li:nth-child(7) .icon {
    transition-delay: 400ms; }
  .header-region .follow-button:hover + .share-button {
    transform: translate3d(-181px, 0, 0);
    transition-delay: 0ms; }
  @media screen and (min-width: 0\0) {
    .header-region {
      /* IE9 and IE10 rule sets go here */ }
      .header-region .follow-buttons, .header-region .share-buttons, .header-region .follow-button, .header-region .share-button {
        background: transparent; }
      .header-region ul ul {
        background: transparent; }
        .header-region ul ul:hover {
          background: #cbb270; } }

.footer-region {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  letter-spacing: 0.1em; }
  .footer-region .icon {
    fill: #cbb270; }
  .footer-region span {
    color: #cbb270;
    cursor: pointer; }
  .footer-region .left {
    position: absolute;
    bottom: 40px;
    left: 40px;
    font: 400 14px/14px 'Futura', 'Helvetica', sans-serif;
    z-index: 2; }
  .footer-region .right {
    position: absolute;
    bottom: 40px;
    right: 40px;
    font: 500 12px/16px 'Futura', 'Helvetica', sans-serif; }
    .footer-region .right a {
      float: left;
      margin-left: 30px;
      color: #FFFFFF; }
  .footer-region .legal-button .icon {
    height: 6px;
    width: 10px;
    margin-right: 10px; }
  .footer-region .volume-button {
    padding: 20px;
    position: relative;
    top: 2px; }
    .footer-region .volume-button .icon {
      height: 14px;
      width: 16px;
      margin-right: 10px; }
    .footer-region .volume-button .volume-off {
      display: none; }
    .footer-region .volume-button.muted .volume-off {
      display: inline; }
    .footer-region .volume-button.muted .volume-on {
      display: none; }
  .footer-region .legal-popin {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 40px 0;
    display: none;
    transform: translate3d(0, 20px, 0);
    opacity: 0;
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    background: #000000;
    text-align: center;
    z-index: 1; }
    .footer-region .legal-popin img {
      display: block;
      margin: 0 auto; }
    .footer-region .legal-popin.displayed {
      transform: translate3d(0, 0, 0);
      opacity: 1; }
    .footer-region .legal-popin p {
      text-align: center;
      margin-top: 50px; }
    .footer-region .legal-popin a {
      color: #cbb270;
      font: 400 14px/20px 'Futura', 'Helvetica', sans-serif;
      margin: 10px 20px;
      display: inline-block; }
  .footer-region .copyright-text {
    display: inline-block;
    color: #cbb270;
    font: 400 12px 'Futura', 'Helvetica', sans-serif;
    margin-top: 30px; }

.post-region {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  z-index: 6; }
  .post-region .background {
    position: absolute;
    backface-visibility: hidden;
    display: none; }
  .post-region .front {
    width: 900px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -250px 0 0 -450px;
    z-index: 1; }
  .post-region header {
    text-align: center;
    width: 100%; }
  .post-region .title {
    display: block;
    font: 400 20px/28px 'F37Bella', 'Helvetica', sans-serif;
    color: #cbb270;
    overflow: hidden;
    position: relative;
    letter-spacing: 0.1em;
    margin: 45px 0 40px;
    padding: 0 100px;
    box-sizing: border-box;
    height: 80px;
    overflow: hidden; }
    .post-region .title .mask {
      height: 80px;
      overflow: hidden; }
    .post-region .title a {
      color: #cbb270; }
  .post-region .count {
    font: 500 14px/30px 'Futura', 'Helvetica', sans-serif;
    color: #cbb270;
    letter-spacing: 0.2em;
    margin-bottom: 35px;
    overflow: hidden;
    margin-top: 20px;
    margin-left: -18px;
    position: absolute;
    top: 110px;
    left: 0px;
    width: 200px;
    text-align: left; }
  .post-region figure {
    width: 900px;
    height: 400px;
    position: relative;
    overflow: hidden; }
    .post-region figure .mask {
      position: relative;
      overflow: hidden;
      height: 100%;
      width: 100%;
      max-width: 700px;
      margin: 0 auto; }
    .post-region figure img, .post-region figure iframe {
      display: block;
      position: absolute; }
  .post-region .tags {
    width: 100%;
    text-align: center;
    color: #cbb270;
    font: 500 14px/20px 'Futura', 'Helvetica', sans-serif;
    letter-spacing: 0.2em;
    max-width: 520px;
    margin: 0 auto;
    line-height: 2; }
    .post-region .tags > * {
      display: inline-block;
      margin: 0 10px; }
  .post-region .share-region {
    bottom: 50px;
    right: 50px;
    border: 1px solid rgba(203, 178, 112, 0.7); }
    .post-region .share-region .icon {
      fill: #cbb270; }
  .post-region .arrow {
    position: absolute;
    top: 50%;
    margin-top: -32px;
    padding: 30px 0;
    cursor: pointer; }
    .post-region .arrow .icon {
      fill: #cbb270;
      height: 5px;
      width: 55px; }
  .post-region .arrow-prev {
    left: -20px; }
  .post-region .arrow-next {
    right: 20px; }
  .post-region .close-button {
    height: 50px;
    width: 50px;
    position: absolute;
    top: 0px;
    left: -50px;
    cursor: pointer;
    font: 400 25px/25px 'F37Bella', 'Helvetica', sans-serif;
    color: #cbb270;
    width: 100px;
    text-align: right; }
    .post-region .close-button span {
      display: block;
      height: 100%;
      width: 2px;
      background: #cbb270;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px; }
      .post-region .close-button span:nth-child(1) {
        transform: rotate(45deg); }
      .post-region .close-button span:nth-child(2) {
        transform: rotate(-45deg); }
  .post-region .nav-progress {
    position: absolute;
    top: 80px;
    left: -50px;
    color: #cbb270;
    font: 400 18px/32px 'F37Bella', 'Helvetica', sans-serif;
    letter-spacing: 0.02em; }
    .post-region .nav-progress ul {
      color: #cbb270;
      font: 400 32px/32px 'F37Bella', 'Helvetica', sans-serif;
      height: 32px;
      width: 62px;
      overflow: hidden;
      float: left;
      position: relative;
      margin-right: 7px;
      top: 5px; }
      .post-region .nav-progress ul li {
        position: absolute;
        right: 0;
        top: 0; }
  .post-region .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: none; }
  .post-region .mask {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .post-region figure img, .post-region figure iframe {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .post-region .nav-progress li {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .post-region.hidden-prev .overlay {
    display: block; }
  .post-region.hidden-prev figure .mask {
    transform: translate3d(115%, 0, 0); }
    .post-region.hidden-prev figure .mask img, .post-region.hidden-prev figure .mask iframe {
      transform: translate3d(-91%, 0, 0); }
  .post-region.hidden-prev header .mask {
    transform: translate3d(0, 100%, 0); }
  .post-region.hidden-prev header .title .mask {
    transition-delay: 100ms; }
  .post-region.hidden-prev header .count .mask {
    transition-delay: 200ms; }
  .post-region.hidden-prev .nav-progress li {
    transform: translate3d(0, 100%, 0); }
  .post-region.pre-displayed-prev figure .mask {
    transform: translate3d(-101%, 0, 0); }
    .post-region.pre-displayed-prev figure .mask img, .post-region.pre-displayed-prev figure .mask iframe {
      transform: translate3d(91%, 0, 0); }
  .post-region.pre-displayed-prev header .mask {
    transform: translate3d(0, -100%, 0); }
  .post-region.pre-displayed-prev header .title .mask {
    transition-delay: 100ms; }
  .post-region.pre-displayed-prev header .count .mask {
    transition-delay: 200ms; }
  .post-region.pre-displayed-prev .nav-progress li {
    transform: translate3d(0, -100%, 0); }
  .post-region.hidden-next .overlay {
    display: block; }
  .post-region.hidden-next figure .mask {
    transform: translate3d(-115%, 0, 0); }
    .post-region.hidden-next figure .mask img, .post-region.hidden-next figure .mask iframe {
      transform: translate3d(91%, 0, 0); }
  .post-region.hidden-next header .mask {
    transform: translate3d(0, -100%, 0); }
  .post-region.hidden-next header .title .mask {
    transition-delay: 100ms; }
  .post-region.hidden-next header .count .mask {
    transition-delay: 200ms; }
  .post-region.hidden-next .nav-progress li {
    transform: translate3d(0, -100%, 0); }
  .post-region.pre-displayed-next figure .mask, .post-region.pre-displayed figure .mask {
    transform: translate3d(101%, 0, 0); }
    .post-region.pre-displayed-next figure .mask img, .post-region.pre-displayed-next figure .mask iframe, .post-region.pre-displayed figure .mask img, .post-region.pre-displayed figure .mask iframe {
      transform: translate3d(-91%, 0, 0); }
  .post-region.pre-displayed-next header .mask, .post-region.pre-displayed header .mask {
    transform: translate3d(0, 100%, 0); }
  .post-region.pre-displayed-next header .title .mask, .post-region.pre-displayed header .title .mask {
    transition-delay: 100ms; }
  .post-region.pre-displayed-next header .count .mask, .post-region.pre-displayed header .count .mask {
    transition-delay: 200ms; }
  .post-region.pre-displayed-next .nav-progress li, .post-region.pre-displayed .nav-progress li {
    transform: translate3d(0, 100%, 0); }

.tumblr-region {
  position: relative;
  overflow: hidden;
  display: none;
  z-index: 2; }
  .tumblr-region .background {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    backface-visibility: hidden;
    display: none; }
  .tumblr-region .share-region {
    top: 50%;
    left: 50%;
    margin: -29px 0 0 -88px;
    background: black; }
  .tumblr-region canvas {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 1000; }
  .tumblr-region .big-title {
    font: 400 70px/70px 'BRAGGADO', 'Helvetica', sans-serif;
    color: #cbb270;
    text-align: center;
    margin-top: 150px; }

.posts-region {
  padding: 100px 0 230px;
  background: #FFFFFF; }

.tags-container {
  font: 500 14px/16px 'F37Bella', 'Helvetica', sans-serif;
  text-align: center;
  letter-spacing: 0.2em;
  position: relative;
  z-index: 5;
  margin-top: 70px; }
  .tags-container li {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
    overflow: hidden;
    color: #cbb270; }
    .tags-container li span {
      display: block;
      color: #cbb270;
      border-bottom: 1px solid rgba(203, 178, 112, 0);
      transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
    .tags-container li.active span, .tags-container li:hover span {
      color: #000000;
      border-bottom: 1px solid #000000; }

.posts-collection {
  margin: 0 auto;
  width: 960px; }
  .posts-collection:after {
    content: '';
    display: block;
    clear: both; }
  .posts-collection li {
    width: 320px;
    height: 320px;
    overflow: hidden;
    float: left;
    position: relative; }
    .posts-collection li:nth-child(12n+2) {
      margin-right: 320px; }
    .posts-collection li:nth-child(12n+6) {
      margin-left: 320px; }
    .posts-collection li:nth-child(12n+8), .posts-collection li:nth-child(12n+10) {
      margin-left: 160px; }
    .posts-collection li:nth-child(12n+12) {
      margin-left: 480px; }
  .posts-collection figure {
    position: relative;
    background: #000000; }
  .posts-collection img {
    display: block;
    height: 100%;
    opacity: 0.8; }
  .posts-collection .gradient-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; }
  .posts-collection li {
    opacity: 0;
    transition: opacity 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5); }
  .posts-collection figure {
    transition: transform 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: scale(1.1);
    opacity: 0; }
  .posts-collection .gradient-img {
    opacity: 1;
    transition: opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 100ms, transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 100ms; }
  .posts-collection img {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .posts-collection .share-region {
    opacity: 0;
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, opacity 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .posts-collection li.displayed:hover .gradient-img {
    transform: scale(1.05);
    opacity: 0;
    transition: opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .posts-collection li.displayed:hover img {
    transform: scale(1.15);
    transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .posts-collection li.displayed:hover .share-region {
    opacity: 1;
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1) 100ms, opacity 700ms cubic-bezier(0.19, 1, 0.22, 1) 100ms; }
  .posts-collection li.displayed {
    opacity: 1; }
    .posts-collection li.displayed figure {
      transform: scale(1); }
  .posts-collection figure.loaded {
    opacity: 1; }

.share-region {
  position: absolute;
  height: 56px;
  width: 174px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 0 5px;
  box-sizing: initial; }
  .share-region .icon {
    fill: #FFFFFF;
    height: 20px;
    width: 20px;
    position: relative; }
  .share-region > div > * {
    float: left;
    width: 43px;
    height: 56px; }
  .share-region iframe {
    height: 20px;
    width: 20px;
    margin: 0 auto;
    position: relative; }
  .share-region .facebook-share .icon {
    top: 16px; }
  .share-region .twitter-share .icon {
    top: 17px; }
  .share-region .reblog_button .icon {
    top: 17px; }
  .share-region .like_button iframe {
    top: 17px; }

.posts-loader {
  width: 200px;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  position: relative;
  margin: 50px auto;
  left: 50px;
  z-index: 100;
  overflow: hidden;
  clear: both; }
  .posts-loader span {
    display: block;
    height: 100%;
    width: 100%;
    background: #cbb270;
    transform-origin: 0 50%;
    top: 0;
    left: 0;
    position: absolute; }
    .posts-loader span:nth-child(1) {
      animation: posts-loader-first 3s infinite cubic-bezier(0.55, 0.085, 0.68, 0.53); }
    .posts-loader span:nth-child(2) {
      animation: posts-loader-second 3s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955); }

@keyframes posts-loader-first {
  0% {
    transform: translate3d(0, 0, 0) scale(0, 1); }

  50% {
    transform: translate3d(102%, 0, 0) scale(1, 1); }

  70% {
    transform: translate3d(102%, 0, 0) scale(1, 1); }

  100% {
    transform: translate3d(102%, 0, 0) scale(1, 1); } }

@keyframes posts-loader-second {
  0% {
    transform: translate3d(-100%, 0, 0) scale(0.5, 1); }

  5% {
    transform: translate3d(-100%, 0, 0) scale(0.5, 1); }

  90% {
    transform: translate3d(100%, 0, 0) scale(0.8, 1); }

  100% {
    transform: translate3d(100%, 0, 0) scale(0.8, 1); } }

@media screen and (min-width: 0\0) {
  /* IE9 and IE10 rule sets go here */
  .share-region {
    width: 184px; } }

#tumblr_controls {
  display: none; }

.tumblr_buttons svg {
  width: 20px; }

.about-region {
  overflow: hidden;
  width: 100%; }
  .about-region .center {
    position: relative;
    max-width: 1100px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    border-left: 1px solid #A7A7A7;
    border-right: 1px solid #A7A7A7; }
  .about-region h1 {
    font: 400 100px/100px 'BRAGGADO', 'Helvetica', sans-serif;
    color: #cbb270;
    letter-spacing: 1em;
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 100%;
    height: 260px;
    position: fixed;
    left: 0;
    z-index: 2;
    overflow: hidden;
    position: absolute;
    top: 335px;
    margin-top: 0; }
  .about-region .col {
    float: left;
    box-sizing: border-box;
    position: relative;
    overflow: hidden; }
    .about-region .col img {
      display: block;
      width: 100%; }
  .about-region .col-small {
    width: 33.3333333333%;
    font: 400 26px/30px 'F37Bella', 'Helvetica', sans-serif;
    color: #A7A7A7;
    letter-spacing: 0.02em;
    text-align: right; }
    .about-region .col-small .pic {
      bottom: 0; }
  .about-region .col-large {
    width: 66.6666666667%;
    border-left: 1px solid #A7A7A7; }
    .about-region .col-large img {
      margin-bottom: 230px; }
  .about-region .content {
    height: 850px;
    padding-bottom: 24px;
    position: relative; }
  .about-region .part-content {
    box-sizing: border-box;
    padding: 0 70px;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0; }
  .about-region .pic {
    position: absolute;
    overflow: hidden; }
  .about-region .pic-left {
    right: 100%;
    top: 240px; }
  .about-region .pic-right {
    left: 100%;
    top: 840px; }
  .about-region .col-large .pic {
    position: relative; }
  .about-region .part-nav {
    margin-top: 750px;
    padding-right: 70px; }
    .about-region .part-nav li {
      margin-bottom: 20px;
      cursor: pointer; }
      .about-region .part-nav li span {
        display: inline-block; }
  .about-region .part-about .hat {
    font: 400 20px/28px 'F37Bella', 'Helvetica', sans-serif;
    letter-spacing: 0.2em;
    margin-bottom: 45px; }
  .about-region .part-cast {
    font: 400 20px/48px 'F37Bella', 'Helvetica', sans-serif;
    letter-spacing: 0.02em;
    /*p:nth-child(1) {
			font:500 16px/48px $font-text;
		}*/ }
    .about-region .part-cast li {
      clear: both; }
    .about-region .part-cast p {
      float: left;
      width: 50%;
      overflow: hidden;
      position: relative; }
    .about-region .part-cast span {
      display: block; }
    .about-region .part-cast p {
      text-align: center;
      font: 400 26px/30px 'F37Bella', 'Helvetica', sans-serif;
      letter-spacing: 0.25em;
      margin-top: 81px;
      width: 100%; }
  .about-region .letter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .about-region .letter .mask {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden; }
    .about-region .letter span {
      display: block;
      position: relative; }
  .about-region .letter-t span {
    top: 0px;
    left: -178px; }
  .about-region .letter-h span {
    top: 0px;
    left: 0px; }
  .about-region .letter-e span {
    top: 0px;
    left: 180px; }
  .about-region .letter-g span {
    top: 170px;
    left: -320px; }
  .about-region .letter-r span {
    top: 170px;
    left: -146px; }
  .about-region .letter-i span {
    top: 170px;
    left: 0px; }
  .about-region .letter-n span {
    top: 170px;
    left: 147px; }
  .about-region .letter-d span {
    top: 170px;
    left: 330px; }
  .about-region .part-nav li {
    transition: color 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
    .about-region .part-nav li span {
      border-bottom: 1px solid transparent;
      transition: border-color 500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
    .about-region .part-nav li:hover, .about-region .part-nav li.active {
      color: #000000; }
      .about-region .part-nav li:hover span, .about-region .part-nav li.active span {
        border-bottom: 1px solid #000000; }
  .about-region .part-about {
    transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1) 300ms; }
    .about-region .part-about.hidden {
      transform: translate3d(101%, 0, 0);
      transition-delay: 0ms; }
    .about-region .part-about.pre-displayed {
      transform: translate3d(-101%, 0, 0);
      transition-duration: 0ms; }
  .about-region .part-cast p {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 300ms; }
  .about-region .part-cast li span {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 300ms; }
  .about-region .part-cast li p:nth-child(2) span {
    transition-delay: 400ms; }
  .about-region .part-cast.hidden p {
    transform: translate3d(101%, 0, 0);
    transition-delay: 0ms; }
  .about-region .part-cast.hidden li span {
    transform: translate3d(101%, 0, 0);
    transition-delay: 0ms; }
  .about-region .part-cast.hidden li p:nth-child(2) span {
    transition-delay: 0ms; }
  .about-region .part-cast.pre-displayed p {
    transform: translate3d(-101%, 0, 0);
    transition-duration: 0ms;
    transition-delay: 0ms; }
  .about-region .part-cast.pre-displayed li span {
    transform: translate3d(-101%, 0, 0);
    transition-duration: 0ms;
    transition-delay: 0ms; }
  .about-region .part-nav {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 200ms; }
  .about-region .content {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 400ms; }
  .about-region .col-small img {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 500ms; }
  .about-region .col-large img {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 500ms; }
  .about-region .pic-left img {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 500ms; }
  .about-region .pic-right img {
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 600ms; }
  .about-region.pre-displayed .part-nav, .about-region.pre-displayed .content {
    transform: translate3d(-100%, 0, 0); }
  .about-region.pre-displayed .col-small img {
    transform: translate3d(100%, 0, 0); }
  .about-region.pre-displayed .col-large img {
    transform: translate3d(100%, 0, 0); }
  .about-region.pre-displayed .pic-left img {
    transform: translate3d(100%, 0, 0); }
  .about-region.pre-displayed .pic-right img {
    transform: translate3d(100%, 0, 0); }

.video-region {
  overflow: hidden;
  position: relative; }
  .video-region .shrink {
    height: 32px;
    width: 32px; }
  .video-region .expand {
    height: 32px;
    width: 32px; }
  .video-region .background {
    position: absolute; }
    .video-region .background img {
      display: block;
      height: 100%;
      width: 100%; }
  .video-region .slider-nav {
    font: 500 14px/16px 'Futura', 'Helvetica', sans-serif;
    text-align: center;
    letter-spacing: 0.2em;
    margin-bottom: 50px; }
    .video-region .slider-nav li {
      display: inline-block;
      margin: 0 10px;
      cursor: pointer;
      overflow: hidden; }
      .video-region .slider-nav li span {
        display: inline-block;
        color: #A7A7A7;
        border-bottom: 1px solid rgba(167, 167, 167, 0); }
        .video-region .slider-nav li span span {
          border-bottom: 0; }
      .video-region .slider-nav li.active span, .video-region .slider-nav li:hover span {
        color: #FFFFFF;
        border-bottom: 1px solid #FFFFFF; }
        .video-region .slider-nav li.active span span, .video-region .slider-nav li:hover span span {
          border-bottom: 0; }
  .video-region .nav-progress {
    position: absolute;
    top: 80px;
    left: -140px;
    color: #A7A7A7;
    font: 400 18px/32px 'F37Bella', 'Helvetica', sans-serif;
    letter-spacing: 0.02em; }
    .video-region .nav-progress ul {
      color: #FFFFFF;
      font: 400 32px/32px 'F37Bella', 'Helvetica', sans-serif;
      height: 32px;
      width: 62px;
      overflow: hidden;
      float: left;
      position: relative;
      margin-right: 7px;
      top: 5px; }
      .video-region .nav-progress ul li {
        position: absolute;
        right: 0;
        top: 0;
        transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
        transform: translate3d(0, -100%, 0); }
        .video-region .nav-progress ul li.active {
          transform: translate3d(0, 0, 0); }
          .video-region .nav-progress ul li.active ~ li {
            transform: translate3d(0, 100%, 0); }
  .video-region .slider-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -287px 0 0 -450px;
    z-index: 2;
    height: 574px; }
    .video-region .slider-container .close-button {
      position: absolute;
      z-index: 101;
      top: 30px;
      right: 30px;
      font: 400 24px/24px 'Futura', 'Helvetica', sans-serif;
      color: #cbb270; }
      .video-region .slider-container .close-button .shrink {
        display: none; }
      .video-region .slider-container .close-button .expand {
        display: block; }
  .video-region .slider {
    position: relative;
    height: 508px;
    width: 901px;
    overflow: hidden; }
    .video-region .slider li {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
      .video-region .slider li .video {
        transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
      .video-region .slider li.prev {
        transform: translate3d(-100%, 0, 0); }
        .video-region .slider li.prev .video {
          transform: translate3d(50%, 0, 0); }
      .video-region .slider li.next {
        transform: translate3d(100%, 0, 0); }
        .video-region .slider li.next .video {
          transform: translate3d(-50%, 0, 0); }
  .video-region .video {
    width: 901px;
    height: 508px;
    position: relative;
    overflow: hidden; }
  .video-region iframe {
    display: block;
    height: 100%;
    width: 100%; }
  .video-region .background, .video-region .slider-container {
    transition: transform 3000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .video-region .slider-nav li span {
    transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, border 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, color 700ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .video-region .slider-nav li:nth-child(1) span {
    transition-delay: 200ms; }
  .video-region .slider-nav li:nth-child(2) span {
    transition-delay: 300ms; }
  .video-region .slider-nav li:nth-child(3) span {
    transition-delay: 400ms; }
  .video-region .slider-nav li:nth-child(4) span {
    transition-delay: 500ms; }
  .video-region.pre-displayed .background {
    transform: scale(1.1); }
  .video-region.pre-displayed .slider-container {
    transform: scale(0.9); }
  .video-region.pre-displayed .slider-nav li span {
    transform: translate3d(-100%, 0, 0); }
  .video-region .slider-container.fullscreen-video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: #000000; }
    .video-region .slider-container.fullscreen-video .close-button {
      position: absolute;
      z-index: 101;
      top: 10px;
      right: 10px;
      font: 400 32px/32px 'Futura', 'Helvetica', sans-serif;
      color: #cbb270; }
      .video-region .slider-container.fullscreen-video .close-button .shrink {
        display: block; }
      .video-region .slider-container.fullscreen-video .close-button .expand {
        display: none; }
    .video-region .slider-container.fullscreen-video .slider-nav {
      display: none; }
    .video-region .slider-container.fullscreen-video .slider {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      height: 90%;
      width: 100%;
      overflow: hidden; }
      .video-region .slider-container.fullscreen-video .slider li {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
        .video-region .slider-container.fullscreen-video .slider li .video {
          transition: transform 1500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
        .video-region .slider-container.fullscreen-video .slider li.prev {
          transform: translate3d(-100%, 0, 0); }
          .video-region .slider-container.fullscreen-video .slider li.prev .video {
            transform: translate3d(50%, 0, 0); }
        .video-region .slider-container.fullscreen-video .slider li.next {
          transform: translate3d(100%, 0, 0); }
          .video-region .slider-container.fullscreen-video .slider li.next .video {
            transform: translate3d(-50%, 0, 0); }

.safari .safari-only {
  font-family: 'Helvetica';
  text-decoration: none; }

.menu-region > div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 15;
  overflow: hidden; }
.menu-region .icon {
  fill: #000000; }
.menu-region .mask {
  height: 100%;
  width: 100%; }
.menu-region .scale-lower {
  height: 100%;
  width: 100%; }
.menu-region .button-menu {
  position: absolute;
  bottom: 30px;
  left: 30px;
  font: 500 14px/40px 'Futura', 'Helvetica', sans-serif;
  letter-spacing: 0.2em;
  color: #000000;
  border: 1px solid #000000;
  padding: 0 10px;
  cursor: pointer;
  z-index: 4; }
  .menu-region .button-menu .icon {
    margin-right: 10px;
    position: relative; }
.menu-region .title {
  font: 400 26px/26px 'BRAGGADO', 'Helvetica', sans-serif;
  position: absolute;
  bottom: 80px;
  left: 80px;
  text-align: right;
  z-index: 4;
  color: #000000; }
  .menu-region .title span {
    display: block;
    font: 400 14px/24px 'F37Bella', 'Helvetica', sans-serif;
    letter-spacing: 0.08em; }
.menu-region .worldwide-button {
  font: 400 14px/20px 'Futura', 'Helvetica', sans-serif;
  color: #000000;
  position: absolute;
  bottom: 4px;
  right: 10px;
  z-index: 6;
  padding: 20px; }
.menu-region .background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }
  .menu-region .background div {
    height: 100%;
    width: 14.2857142857%;
    float: left;
    overflow: hidden; }
    .menu-region .background div div {
      width: 102%;
      position: relative;
      left: -1%;
      background: #FFFFFF; }
.menu-region .menu-button-container .character-infos .button {
  cursor: pointer;
  width: 130px;
  height: 42px; }
  .menu-region .menu-button-container .character-infos .button .mask span {
    width: 130px; }

.menu-characters {
  height: 100%;
  max-width: 1400px;
  padding: 0 50px;
  box-sizing: border-box;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  pointer-events: none; }
  .menu-characters li {
    height: 100%;
    width: 12.5%;
    float: left; }
  .menu-characters .mask {
    width: 200%;
    left: 0%;
    position: relative; }
  .menu-characters img {
    display: block;
    position: absolute;
    bottom: 30%;
    width: 100%; }
  .menu-characters li:nth-child(1) .mask {
    width: 93%;
    left: 3%; }
  .menu-characters li:nth-child(2) .mask {
    width: 100%; }
  .menu-characters li:nth-child(3) .mask {
    width: 105%;
    left: -10%; }
  .menu-characters li:nth-child(4) .mask {
    width: 118%;
    left: -15%; }
  .menu-characters li:nth-child(5) .mask {
    width: 130%;
    left: -13%; }
  .menu-characters li:nth-child(6) .mask {
    width: 90%;
    left: 12%; }
  .menu-characters li:nth-child(7) .mask {
    width: 70%;
    left: 10%;
    transform: rotate(10deg); }
  .menu-characters li:nth-child(8) .mask {
    width: 130%;
    left: -15%; }

.main-nav {
  height: 100%;
  max-width: 1400px;
  padding: 0 50px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  z-index: 3; }
  .main-nav a, .main-nav .disabled {
    display: block;
    height: 100%;
    width: 100%;
    outline: none; }
  .main-nav li {
    height: 100%;
    width: 12.5%;
    float: left;
    position: relative;
    cursor: pointer; }
  .main-nav img {
    display: block;
    max-width: 100%; }
  .main-nav .front {
    position: absolute;
    bottom: 30%;
    margin-bottom: -80px;
    left: 0;
    width: 100%;
    height: auto;
    text-align: center;
    overflow: visible; }
    .main-nav .front .safari-show {
      display: none; }
  .main-nav strong {
    color: #cbb270;
    font: 500 16px/20px 'Futura', 'Helvetica', sans-serif;
    letter-spacing: 0.2em;
    display: block;
    height: 40px;
    margin-top: 20px; }
  .main-nav span {
    position: absolute;
    bottom: -55px;
    left: 0;
    width: 100%;
    color: #FFFFFF;
    font: 400 20px/20px 'F37Bella', 'Helvetica', sans-serif;
    letter-spacing: 0.25em;
    color: #000000;
    opacity: 0; }
  .main-nav .band {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #cbb270; }
  .main-nav .disabled .band {
    background: #A7A7A7; }
  .main-nav .disabled strong {
    color: #A7A7A7; }
  .main-nav .band {
    transform: translate3d(0, 100%, 0);
    transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .main-nav strong {
    transition: color 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
  .main-nav span {
    transition: opacity 1000ms cubic-bezier(0.19, 1, 0.22, 1) 100ms; }
  .main-nav li:hover .band {
    transform: translate3d(0, 0, 0); }
  .main-nav li:hover strong {
    color: #000000; }
  .main-nav li:hover span {
    opacity: 1; }

.safari .main-nav .front .safari-show {
  display: block; }
  .safari .main-nav .front .safari-show p {
    display: inline-block; }
    .safari .main-nav .front .safari-show p:first-child {
      left: 6px;
      position: relative;
      width: 16px; }
.safari .main-nav .front .safari-hide {
  display: none; }

.menu-region > div {
  transition-delay: 1000ms;
  transform: translate3d(-100%, 0, 0); }
.menu-region .mask {
  overflow: hidden;
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
.menu-region .background div > div {
  transform: translate3d(-101%, 0, 0); }
.menu-region .social li {
  transform: translate3d(50px, 0, 0);
  opacity: 0; }
.menu-region .title {
  transform: translate3d(-50px, 0, 0);
  opacity: 0; }
.menu-region .main-nav a, .menu-region .main-nav .disabled {
  overflow: hidden;
  position: relative; }
.menu-region .main-nav img {
  transform: translate3d(100%, 0, 0);
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
.menu-region .main-nav .mask {
  transform: translate3d(100%, 0, 0);
  transition: transform 0ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
.menu-region.displayed > div {
  transform: translate3d(0, 0, 0);
  transition-delay: 0ms; }
.menu-region.displayed .mask, .menu-region.displayed img {
  transform: translate3d(0, 0, 0);
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
.menu-region.displayed .title {
  opacity: 1; }
.menu-region.displayed .main-nav li:nth-child(1) .mask, .menu-region.displayed .main-nav li:nth-child(1) img, .menu-region.displayed .menu-characters li:nth-child(1) .mask, .menu-region.displayed .menu-characters li:nth-child(1) img {
  transition-delay: 300ms; }
.menu-region.displayed .main-nav li:nth-child(2) .mask, .menu-region.displayed .main-nav li:nth-child(2) img, .menu-region.displayed .menu-characters li:nth-child(2) .mask, .menu-region.displayed .menu-characters li:nth-child(2) img {
  transition-delay: 400ms; }
.menu-region.displayed .main-nav li:nth-child(3) .mask, .menu-region.displayed .main-nav li:nth-child(3) img, .menu-region.displayed .menu-characters li:nth-child(3) .mask, .menu-region.displayed .menu-characters li:nth-child(3) img {
  transition-delay: 500ms; }
.menu-region.displayed .main-nav li:nth-child(4) .mask, .menu-region.displayed .main-nav li:nth-child(4) img, .menu-region.displayed .menu-characters li:nth-child(4) .mask, .menu-region.displayed .menu-characters li:nth-child(4) img {
  transition-delay: 600ms; }
.menu-region.displayed .main-nav li:nth-child(5) .mask, .menu-region.displayed .main-nav li:nth-child(5) img, .menu-region.displayed .menu-characters li:nth-child(5) .mask, .menu-region.displayed .menu-characters li:nth-child(5) img {
  transition-delay: 700ms; }
.menu-region.displayed .main-nav li:nth-child(6) .mask, .menu-region.displayed .main-nav li:nth-child(6) img, .menu-region.displayed .menu-characters li:nth-child(6) .mask, .menu-region.displayed .menu-characters li:nth-child(6) img {
  transition-delay: 800ms; }
.menu-region.displayed .main-nav li:nth-child(7) .mask, .menu-region.displayed .main-nav li:nth-child(7) img, .menu-region.displayed .menu-characters li:nth-child(7) .mask, .menu-region.displayed .menu-characters li:nth-child(7) img {
  transition-delay: 900ms; }
.menu-region.displayed .main-nav li:nth-child(8) .mask, .menu-region.displayed .main-nav li:nth-child(8) img, .menu-region.displayed .menu-characters li:nth-child(8) .mask, .menu-region.displayed .menu-characters li:nth-child(8) img {
  transition-delay: 1000ms; }
.menu-region.displayed .background div > div {
  transform: translate3d(0, 0, 0);
  transition: transform 1400ms cubic-bezier(0.19, 1, 0.22, 1) 0ms; }
.menu-region.displayed .background div:nth-child(1) div {
  transition-delay: 0ms; }
.menu-region.displayed .background div:nth-child(2) div {
  transition-delay: 50ms; }
.menu-region.displayed .background div:nth-child(3) div {
  transition-delay: 100ms; }
.menu-region.displayed .background div:nth-child(4) div {
  transition-delay: 150ms; }
.menu-region.displayed .background div:nth-child(5) div {
  transition-delay: 200ms; }
.menu-region.displayed .background div:nth-child(6) div {
  transition-delay: 250ms; }
.menu-region.displayed .background div:nth-child(7) div {
  transition-delay: 300ms; }
.menu-region.pre-displayed .mask, .menu-region.pre-displayed img {
  transition-duration: 0;
  transform: translate3d(100%, 0, 0); }
.menu-region.hidden .mask-container {
  transform: translate3d(100%, 0, 0); }
.menu-region.hidden .mask-center {
  transform: translate3d(-90%, 0, 0); }

.safari .menu-region {
  position: relative; }
  .safari .menu-region .background div {
    position: absolute; }
  .safari .menu-region .background div:nth-child(1) {
    left: 0; }
  .safari .menu-region .background div:nth-child(2) {
    left: 14.2%; }
  .safari .menu-region .background div:nth-child(3) {
    left: 28.4%; }
  .safari .menu-region .background div:nth-child(4) {
    left: 42.6%; }
  .safari .menu-region .background div:nth-child(5) {
    left: 56.8%; }
  .safari .menu-region .background div:nth-child(6) {
    left: 71%; }
  .safari .menu-region .background div:nth-child(7) {
    left: 85.2%;
    width: 15%; }

@media screen and (max-width: 1150px) {
  .menu-region .title {
    bottom: 24px; }
  .menu-region .button-menu {
    left: 200px; }
  .main-nav strong {
    font-size: 12px;
    line-height: 16px; }
  .home-region .character-name {
    transform: scale(0.7); }
  .home-region .character-infos .quote {
    font-size: 20px;
    line-height: 27px; }
  .home-region .button-more {
    width: 177px;
    height: 242px; }
    .home-region .button-more .button {
      padding: 0 20px;
      line-height: 40px;
      width: 150px;
      height: 40px; }
  .video-region .shrink, .video-region .expand {
    height: 20px;
    width: 20px; }
  .video-region .close-button {
    top: 2px !important;
    right: 2px !important; }
  .video-region .slider-container:not(.fullscreen-video) {
    height: 454px;
    margin: -227px 0 0 -360px; }
    .video-region .slider-container:not(.fullscreen-video) .slider-nav {
      margin-bottom: 30px; }
    .video-region .slider-container:not(.fullscreen-video) .slider, .video-region .slider-container:not(.fullscreen-video) .video {
      width: 720px;
      height: 405px; }
    .video-region .slider-container:not(.fullscreen-video) .nav-progress {
      top: 60px;
      left: -120px; } }

@media screen and (max-height: 700px) {
  .post-region figure {
    height: 300px; } }

