.video-region {
    overflow: hidden;
    position: relative;

    .shrink {
        height: 32px;
        width: 32px;
    }

    .expand {
        height: 32px;
        width: 32px;
    }

    .background {
        position: absolute;

        img {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    // --------------------------------------o Slider

    .slider-nav {
        font: 500 14px/16px $font-text;
        text-align: center;
        letter-spacing: 0.2em;
        margin-bottom: 50px;

        li {
            display: inline-block;
            margin: 0 10px;
            cursor: pointer;
            overflow: hidden;

            span {
                display: inline-block;
                color: $grey;
                border-bottom: 1px solid rgba($grey, 0);

                span {
                    border-bottom: 0;
                }
            }

            &.active, &:hover {
                span {
                    color: $white;
                    border-bottom: 1px solid $white;

                    span {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    .nav-progress {
        position: absolute;
        top: 80px;
        left: -140px;
        color: $grey;
        font: 400 18px/32px $font-title;
        letter-spacing: 0.02em;

        ul {
            color: $white;
            font: 400 32px/32px $font-title;
            height: 32px;
            width: 62px;
            overflow: hidden;
            float: left;
            position: relative;
            margin-right: 7px;
            top: 5px;

            li {
                position: absolute;
                right: 0;
                top: 0;
                transition: transform 700ms $easeOutExpo 0ms;
                transform: translate3d(0, -100%, 0);

                &.active {
                    transform: translate3d(0, 0, 0);

                    & ~ li {
                        transform: translate3d(0, 100%, 0);
                    }
                }
            }
        }
    }

    .slider-container {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -287px 0 0 -450px;
        z-index: 2;
        height: 574px;

        .close-button {
            position: absolute;
            z-index: 101;
            top: 30px;
            right: 30px;
            font: 400 24px/24px $font-text;
            color: $gold;

            .shrink {
                display: none;
            }

            .expand {
                display:block;
            }

        }


    }

    .slider {
        position: relative;
        height: 508px;
        width: 901px;
        overflow: hidden;

        li {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;

            transition: transform 1500ms $easeOutExpo 0ms;

            .video {
                transition: transform 1500ms $easeOutExpo 0ms;
            }

            &.prev {
                transform: translate3d(-100%, 0, 0);

                .video {
                    transform: translate3d(50%, 0, 0);
                }
            }

            &.next {
                transform: translate3d(100%, 0, 0);

                .video {
                    transform: translate3d(-50%, 0, 0);
                }
            }
        }
    }

    // --------------------------------------o Video

    .video {
        width: 901px;
        height: 508px;
        position: relative;
        overflow: hidden;
    }

    iframe {
        display: block;
        height: 100%;
        width: 100%;
    }

    // --------------------------------------o Display Transition

    .background,
    .slider-container {
        transition: transform 3000ms $easeOutExpo 0ms;
    }

    .slider-nav {

        li {
            span {
                transition: transform 1500ms $easeOutExpo 0ms, border 700ms $easeOutExpo 0ms, color 700ms $easeOutExpo 0ms;
            }

            &:nth-child(1) span {
                transition-delay: 200ms;
            }
            &:nth-child(2) span {
                transition-delay: 300ms;
            }
            &:nth-child(3) span {
                transition-delay: 400ms;
            }
            &:nth-child(4) span {
                transition-delay: 500ms;
            }
        }
    }

    &.pre-displayed {

        .background {
            transform: scale(1.1);
        }

        .slider-container {
            transform: scale(0.9);
        }

        .slider-nav {
            li {
                span {
                    transform: translate3d(-100%, 0, 0);
                }
            }
        }

    }

    // fullscreen video
    .slider-container.fullscreen-video {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        height: 100%;
        width: 100%;
        margin: 0;
        background-color: $black;

        .close-button {
            position: absolute;
            z-index: 101;
            top: 10px;
            right: 10px;
            font: 400 32px/32px $font-text;
            color: $gold;

            .shrink {
                display: block;
            }

            .expand {
                display:none;
            }

        }

        .slider-nav {
            display: none;
        }

        .slider {
            position: relative;

            top: 50%;
            transform: translateY(-50%);

            height: 90%;
            width: 100%;
            overflow: hidden;

            li {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                overflow: hidden;

                transition: transform 1500ms $easeOutExpo 0ms;

                .video {
                    transition: transform 1500ms $easeOutExpo 0ms;
                }

                &.prev {
                    transform: translate3d(-100%, 0, 0);

                    .video {
                        transform: translate3d(50%, 0, 0);
                    }
                }

                &.next {
                    transform: translate3d(100%, 0, 0);

                    .video {
                        transform: translate3d(-50%, 0, 0);
                    }
                }
            }

        }

    }

}

.safari .safari-only {
    font-family: 'Helvetica';
    text-decoration: none;
}
