.post-region {
    position:absolute;
    top:0;
    left:0;
    display:none;
    overflow:hidden;
    z-index:6;

    .background {
        position:absolute;
        backface-visibility:hidden;
        display:none;
    }

    .front {
        width:900px;
        position:absolute;
        top:50%;
        left:50%;
        margin:-250px 0 0 -450px;
        z-index:1;
    }

    header {
        text-align:center;
        width:100%;
    }

    .title {
        display:block;
        font:400 20px/28px $font-title;
        color:$gold;
        overflow:hidden;
        position:relative;
        letter-spacing:0.10em;
        margin:45px 0 40px;
        padding:0 100px;
        box-sizing:border-box;
        height:80px;
        overflow:hidden;

        .mask {
            height:80px;
            overflow:hidden;
        }

        a {
            color:$gold;
        }
    }

    .count {
        font:500 14px/30px $font-text;
        color:$gold;
        letter-spacing:0.2em;
        margin-bottom:35px;
        overflow:hidden;
        margin-top:20px;
        margin-left: -18px;
        position:absolute;
        top:110px;
        left:0px;
        width:200px;
        text-align:left;
    }

    figure {
        width:900px;
        height:400px;
        position:relative;
        overflow:hidden;

        .mask {
            position:relative;
            overflow:hidden;
            height:100%;
            width:100%;
            max-width: 700px;
            margin: 0 auto;
        }

        img, iframe {
            display:block;
            position:absolute;
        }
    }

    .tags {
        width:100%;
        text-align:center;
        color:$gold;
        font:500 14px/20px $font-text;
        letter-spacing:0.2em;
        max-width: 520px;
        margin: 0 auto;
        line-height: 2;

        & > * {
            display:inline-block;
            margin:0 10px;
        }
    }

    .share-region {
        bottom:50px;
        right:50px;
        border:1px solid rgba($gold, 0.7);

        .icon {
            fill:$gold;
        }
    }

    .arrow {
        position:absolute;
        top:50%;
        margin-top:-32px;
        padding:30px 0;
        cursor:pointer;

        .icon {
            fill:$gold;
            height:5px;
            width:55px;
        }
    }

    .arrow-prev {
        left:-20px;
    }

    .arrow-next {
        right:20px;
    }

    .close-button {
        height:50px;
        width:50px;
        position:absolute;
        top:0px;
        left:-50px;
        cursor:pointer;
        font:400 25px/25px $font-title;
        color:$gold;
        width:100px;
        text-align:right;

        span {
            display:block;
            height:100%;
            width:2px;
            background:$gold;
            position:absolute;
            top:0;
            left:50%;
            margin-left:-1px;

            &:nth-child(1){
                transform:rotate(45deg);
            }

            &:nth-child(2){
                transform:rotate(-45deg);
            }
        }
    }



    .nav-progress {
        position:absolute;
        top:80px;
        left:-50px;
        color:$gold;
        font:400 18px/32px $font-title;
        letter-spacing:0.02em;

        ul {
            color:$gold;
            font:400 32px/32px $font-title;
            height:32px;
            width:62px;
            overflow:hidden;
            float:left;
            position:relative;
            margin-right:7px;
            top:5px;

            li {
                position:absolute;
                right:0;
                top:0;
            }
        }
    }

    .overlay {
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        z-index:2;
        display:none;
    }

    // -----------o States


    .mask {
        transition:transform 1000ms $easeOutExpo 0ms;
    }

    figure {
        img, iframe {
            transition:transform 1000ms $easeOutExpo 0ms;
        }
    }

    .nav-progress li {
        transition:transform 1000ms $easeOutExpo 0ms;
    }

    &.hidden-prev {
        .overlay {
            display:block;
        }

        figure .mask {
            transform:translate3d(115%, 0, 0);

            img, iframe {
                transform:translate3d(-91%, 0, 0);
            }
        }

        header {
            .mask {
                transform:translate3d(0, 100%, 0);
            }
            .title .mask {
                transition-delay:100ms;
            }
            .count .mask {
                transition-delay:200ms;
            }
        }

        .nav-progress li {
            transform:translate3d(0, 100%, 0);
        }
    }

    &.pre-displayed-prev {
        figure .mask {
            transform:translate3d(-101%, 0, 0);

            img, iframe {
                transform:translate3d(91%, 0, 0);
            }
        }

        header {
            .mask {
                transform:translate3d(0, -100%, 0);
            }
            .title .mask {
                transition-delay:100ms;
            }
            .count .mask {
                transition-delay:200ms;
            }
        }

        .nav-progress li {
            transform:translate3d(0, -100%, 0);
        }
    }

    &.hidden-next {
        .overlay {
            display:block;
        }

        figure .mask {
            transform:translate3d(-115%, 0, 0);

            img, iframe {
                transform:translate3d(91%, 0, 0);
            }
        }

        header {
            .mask {
                transform:translate3d(0, -100%, 0);
            }
            .title .mask {
                transition-delay:100ms;
            }
            .count .mask {
                transition-delay:200ms;
            }
        }

        .nav-progress li {
            transform:translate3d(0, -100%, 0);
        }
    }

    &.pre-displayed-next,
    &.pre-displayed {
        figure .mask {
            transform:translate3d(101%, 0, 0);

            img, iframe {
                transform:translate3d(-91%, 0, 0);
            }
        }

        header {
            .mask {
                transform:translate3d(0, 100%, 0);
            }
            .title .mask {
                transition-delay:100ms;
            }
            .count .mask {
                transition-delay:200ms;
            }
        }

        .nav-progress li {
            transform:translate3d(0, 100%, 0);
        }
    }

}
