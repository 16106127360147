*, *:before, *:after {
	box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
}

body {
    text-transform:uppercase;

    &.fullscreen {
        position:relative;
        overflow:hidden;
    }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.centered-el {
    // center it
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */
}

.svg-icons, .svg-gradients {
    height:0;
    width:0;
    position:absolute;
    bottom:-10px;
    left:-10px;
    overflow:hidden;
}

.icon {
    fill:blue;
}

.gradient-img {
    display:block;
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
}

.no-transition {
    transition:all 0ms $linear 0ms !important;

    * {
        transition:all 0ms $linear 0ms !important;
    }
}
// -------------------------------o Global styles

.text {
    font:500 16px/28px $font-text;
    color:$grey;
    text-transform:none;
    -webkit-font-smoothing:antialiased;

    p {
        margin-bottom:28px;
    }
}

// -------------------------------o Safari specific (disable filter)

.safari .gradient-img {display:none !important;}


// -------------- Portrait mode

.portrait-mode,#block-browser {

    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $black !important;
    height: 100%;
    width: 100%;
    z-index: 1001;

    .message-box-ie {
        margin: 20% auto 0 auto;
        width: 420px;
    }

    .message-box {
        position: absolute;
        top: 50%;
        left: 50%;

        @include transform(translate(-50%,-50%));
        -ms-transform: translate(-50%, -50%);
    }

    .message-box-button {
        float: right;
        margin-right: 20px;
        margin-bottom: 20px;
        margin-top: 10px;

        .button {
            width: 180px;
            font:400 20px/24px $font-text;
            padding: 8px 20px 8px 20px;
            border: 2px solid $gold;
        }
    }

    .title {
        font:400 26px/26px $font-big-title;
        //text-align:right;
        float: left;
        z-index:4;
        color:$gold;
        margin-top: 10px;

        span {
            display:block;
            font:400 14px/24px $font-title;
            letter-spacing:0.08em;
        }
    }

    span.text {
        float: left;
        color : $gold;
        font:400 20px/24px $font-text;
        letter-spacing: 0.25em;

        a {
            text-decoration: underline;
            color: $gold;
        }

    }



}



// -------------------------------o Preloader

.preloader {
    height:100%;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:200;

    background:$black;
    color:$gold;
    font:400 30px/50px $font-big-title;

    .logo {
        height:130px;
        width:130px;
        position:absolute;
        top:50%;
        left:50%;
        margin:-65px 0 0 -65px;
    }

    .letter {
        position:absolute;
        overflow:hidden;
        line-height:32px;
        height:30px;

        span {
            display:block;
        }

        &:nth-child(1)     {top:0;left:0;}
        &:nth-child(2)     {top:0;right:0;}
        &:nth-child(3)     {top:50%;left:50%;margin:-16px 0 0 -14px;}
        &:nth-child(4)     {bottom:0;left:0;}
        &:nth-child(5)     {bottom:0;right:0;}
    }

    .progress {
        height:2px;
        width:100%;
        position:absolute;
        overflow:hidden;

        span {
            display:block;
            height:100%;
            width:100%;
            background:$gold;
            transform:translate3d(-100%, 0, 0);
            transition:transform 1000ms $easeOutExpo 0ms;
        }

        &:nth-child(6)      {top:39px;}
        &:nth-child(7)      {top:89px;}


    }

    &.hidden {
        .letter span {
            transform:translate3d(0, -100%, 0);
            transition:transform 1000ms $easeOutExpo 0ms;
        }
    }

}


// -------------------------------o Button

.button {
    font:400 14px/46px $font-title;
    border:1px solid $black;
    padding:18px 36px;
    letter-spacing:0.1em;
    cursor:pointer;

    &.button-white {
        border-color:rgba($white, 0.3);
        color:$white;
    }

    &.button-menu {
        border-color:rgba($white, 0.3);
        font:500 12px/38px $font-text;
        color:$white;
        letter-spacing:0.2em;
        padding:0 35px 0 38px;
    }

    &.button-gold {
        border-color:rgba($gold, 0.3);
        color:$gold;
        padding:5px 85px 5px 88px;

        a {
            color:$gold;
        }
    }

}


.video {
    position:relative;

    .poster,
    .shield {
        height:100%;
        width:100%;
        display:block;
        position:absolute;
        top:0;
        left:0;
    }

    .poster {
        z-index:4;
    }

    .shield {
        z-index:6;
        cursor:pointer;
    }

    video,
    .media-container {
        display:block;
        height:100%;
        width:100%;
        background:$black;
    }

    .play-button,
    .pause-button {
        height:85px;
        width:85px;
        position:absolute;
        top:50%;
        left:50%;
        margin:-42px 0 0 -42px;
        border:1px solid $white;
        transition:opacity 700ms $easeOutExpo 0ms;
        z-index:5;

        i {
            display:block;
            position:relative;
            top:50%;
            left:50%;
        }
    }

    .pause-button {
        i {
            width:4px;
            height:16px;
            margin:-8px 0 0 -2px;

            &:before, &:after {
                content:'';
                display:block;
                height:100%;
                width:2px;
                position:absolute;
                background:$white;
            }

            &:before {
                left:-2px;
            }

            &:after {
                right:-2px;
            }
        }
    }

    .play-button {
        i {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 0 8px 14px;
            border-color: transparent transparent transparent $white;
            margin:-8px 0 0 -7px
        }
    }

    .timeline-container {
        position:absolute;
        bottom:0;
        height:20px;
        left:0;
        width:100%;
        z-index:3;
        cursor:pointer;

        .progress, .buffer {
            height:2px;
            width:0;
            background:$white;
            position:absolute;
            bottom:0;
            left:0;
        }

        .buffer {
            background:rgba($white, 0.2);
        }
    }

    &.playing, &.paused {
        .play-button,
        .pause-button {
            z-index:1;
        }
        .poster {
            display:none;
        }
        .shield {
            z-index:2;
        }
    }

    .pause-button, .play-button {
        opacity:0;
    }

    &.hover {

        .pause-button, .play-button {
            opacity:1;
        }

        &.playing {
            .play-button {
                opacity:0;
            }
        }

        &.paused, &.stopped {
            .pause-button {
                opacity:0;
            }
        }


    }

    &.stopped {
        .play-button {
            opacity:1;
        }
    }


}



// -----------------------------------------o Leaving transitions


.home-region,
.video-region,
.about-region,
.tumblr-region,
.post-region {
    width:100%;

    & > div {
        height:100%;
        width:100%;
    }

    &.fixed {
        position:fixed;
        top:0;
        z-index:10;
    }

    &.hidden {

        // ---o Masks
        .mask {
            overflow:hidden;
            transition:transform 2000ms $easeOutExpo 0ms;
        }

        .mask-container {
            height:100%;
            width:100%;
            transform:translate3d(101%, 0, 0);
        }

        .mask-center {
            height:100%;
            width:100%;
            transform:translate3d(-91%, 0, 0);
        }
    }

}

.tumblr-region,
.post-region {
    &.fixed {
        z-index:7;
    }
}
