@media screen and (max-width: 1150px) {


// -----------------------o Menu

.menu-region {

	.title {
		bottom:24px;
	}

	.button-menu {
		left:200px;
	}

}

.main-nav {
	strong {
		font-size:12px;
		line-height:16px;
	}
}

// -----------------------o Home

.home-region {

	.character-name {
		transform:scale(0.7);
	}

	.character-infos {
		.quote {
			font-size:20px;
			line-height:27px;
		}
	}

	.button-more {
        width:177px;
        height:242px;

        .button {
        	padding:0 20px;
        	line-height:40px;
        	width:150px;
        	height:40px;
        }
    }
}


// -----------------------o Video

.video-region {

    .shrink,.expand {
        height: 20px;
        width: 20px;
    }

    .close-button {
        top: 2px !important;
        right: 2px !important;
    }





	.slider-container:not(.fullscreen-video) {
		height:454px;
		margin:-227px 0 0 -360px;

        .slider-nav {
            margin-bottom:30px;
        }

        .slider, .video {
            width:720px;
            height:405px;
        }

        .nav-progress {
            top:60px;
            left:-120px;
        }

	}


}



// -------------o End of media queries
}



@media screen and (max-height: 700px) {

	.post-region {
		figure {
			height:300px;
		}
	}

}
