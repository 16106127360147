.tumblr-region {
    position:relative;
    overflow:hidden;
    display:none;
    z-index:2;

    .background {
        position:absolute;
        top:0;
        left:0;
        display:block;
        width:100%;
        backface-visibility:hidden;
        display:none;
    }

    .share-region {
        top:50%;
        left:50%;
        margin:-29px 0 0 -88px;
        background:rgba($black, 1);
    }

    canvas {
        position:absolute;
        top:50px;
        left:50px;
        z-index:1000;
    }

    .big-title {
        font:400 70px/70px $font-big-title;
        color:$gold;
        text-align:center;
        margin-top:150px;
    }

}

.posts-region {
    padding:100px 0 230px;
    background:$white;
}


// ------------------------------------o Nav

.tags-container {
    font:500 14px/16px $font-title;
    text-align:center;
    letter-spacing:0.2em;
    position:relative;
    z-index:5;
    margin-top:70px;

    li {
        display:inline-block;
        margin:0 10px;
        cursor:pointer;
        overflow:hidden;
        color:$gold;

        span {
            display:block;
            color:$gold;
            border-bottom:1px solid rgba($gold, 0);
            transition:all 700ms $easeOutExpo 0ms;
        }

        &.active, &:hover {
            span {
                color:$black;
                border-bottom:1px solid $black;
            }
        }
    }
}

// ------------------------------------o Posts

.posts-collection {
    $containerWidth: 960px;

    margin: 0 auto;
    width: $containerWidth;

    &:after {
        content:'';
        display:block;
        clear:both;
    }

    li {
        width:$containerWidth / 3;
        height:$containerWidth / 3;
        overflow:hidden;
        float:left;
        position:relative;

        &:nth-child(12n+2){
            margin-right:$containerWidth / 3;
        }

        &:nth-child(12n+6){
            margin-left:$containerWidth / 3;
        }

        &:nth-child(12n+8),
        &:nth-child(12n+10){
            margin-left:$containerWidth / 6;
        }

        &:nth-child(12n+12){
            margin-left:$containerWidth / 3 + $containerWidth / 6;
        }
    }

    figure {
        position:relative;
        background:$black;
    }

    img {
        display:block;
        height:100%;
        opacity:0.8;
    }

    .gradient-img {
        position:absolute;
        top:0;
        left:0;
        height:100%;
    }

    // --------------------------o Animations

    li {
        opacity:0;
        transition:opacity 2000ms $easeOutExpo 0ms;
        cursor:pointer;
        background:rgba($black, 0.5);
    }

    figure {
        transition:transform 2000ms $easeOutExpo 0ms, opacity 1000ms $easeOutExpo;
        transform:scale(1.1);
        opacity:0;
    }

    .gradient-img {
        opacity:1;
        transition:opacity 1000ms $easeOutExpo 100ms, transform 1000ms $easeOutExpo 100ms;
    }

    img {
        transition:transform 1000ms $easeOutExpo 0ms;
    }

    .share-region {
        opacity:0;
        transition:transform 700ms $easeOutExpo 0ms, opacity 700ms $easeOutExpo 0ms;
    }

    li.displayed:hover {


        .gradient-img {
            transform:scale(1.05);
            opacity:0;
            transition:opacity 1000ms $easeOutExpo 0ms, transform 1000ms $easeOutExpo 0ms;
        }

        img {
            transform:scale(1.15);
            transition:transform 1500ms $easeOutExpo 0ms;
        }

        .share-region {
            opacity:1;
            transition:transform 700ms $easeOutExpo 100ms, opacity 700ms $easeOutExpo 100ms;
        }
    }

    li.displayed {
        opacity:1;

        figure {
            transform:scale(1);
        }
    }

    figure.loaded{
        opacity:1;
    }
}



// ------------------------------------o Share region

.share-region {
    position:absolute;
    height:56px;
    width:174px;
    border:1px solid rgba($white, 0.7);
    text-align:center;
    padding: 0 5px;
    box-sizing:initial;

    .icon {
        fill:$white;
        height:20px;
        width:20px;
        position:relative;
    }

    & > div > * {
        float:left;
        width:43px;
        height:56px;
    }

    iframe {
        height:20px;
        width:20px;
        margin:0 auto;
        position:relative;
    }

    .facebook-share .icon           {top:16px;}
    .twitter-share .icon            {top:17px;}
    .reblog_button .icon            {top:17px;}
    .like_button iframe             {top:17px;}

}




// ------------------------------------o Loader

.posts-loader {
    width:200px;
    height:1px;
    background:rgba($white, 0.2);
    position:relative;
    margin:50px auto;
    left:50px;
    z-index:100;
    overflow:hidden;
    clear:both;

    span {
        display:block;
        height:100%;
        width:100%;
        background:$gold;
        transform-origin:0 50%;
        top:0;
        left:0;
        position:absolute;

        &:nth-child(1) {
            animation: posts-loader-first 3s infinite $easeInQuad;
        }

        &:nth-child(2) {
            animation: posts-loader-second 3s infinite $easeInOutQuad;
        }

    }


}

@keyframes posts-loader-first {
    0%   {
        transform:translate3d(0, 0, 0) scale(0, 1);
    }
    50% {
        transform:translate3d(102%, 0, 0) scale(1, 1);
    }
    70% {
        transform:translate3d(102%, 0, 0) scale(1, 1);
    }
    100% {
        transform:translate3d(102%, 0, 0) scale(1, 1);
    }
}

@keyframes posts-loader-second {
    0%   {
        transform:translate3d(-100%, 0, 0) scale(0.5, 1);
    }
    5%   {
        transform:translate3d(-100%, 0, 0) scale(0.5, 1);
    }
    90% {
        transform:translate3d(100%, 0, 0) scale(0.8, 1);
    }
    100% {
        transform:translate3d(100%, 0, 0) scale(0.8, 1);
    }
}


@media screen and (min-width:0\0) {
    /* IE9 and IE10 rule sets go here */
    .share-region {
        width: 184px;
    }
}
